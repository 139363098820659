import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import _ from "lodash";
import api from "../services/api";

export function ScrollToTop() {
  const { pathname } = useLocation();

  const [meta, setMeta] = useState(null);
  const [{ data: metaData }] = api.useAxios({ url: "/metas" });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathname.substring(1) === "" && metaData) {
      setMeta(_.find(metaData.metas, { name: "home" }));
    } else if (metaData) {
      setMeta(_.find(metaData.metas, { name: pathname.substring(1) }));
    }
  }, [pathname, metaData]);

  return (
    <Helmet>
      <meta name="description" content={meta && meta.description} />
      <title>{`Farah | ${meta ? meta.title : ""}`}</title>
      <meta name="keywords" content={meta && meta.keywords} />
    </Helmet>
  );
}

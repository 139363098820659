/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ScrollToTop } from "../../components";
import { BiLoaderAlt } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";
import Axios from "axios";
import { CLEAR_CODE, SET_COUPON } from "../../redux/types";

export const CompleteReservation = ({ setStep }) => {
  const dispatch = useDispatch();
  const trip = useSelector((state) => state.tripState.trip);
  const user = useSelector((state) => state.tripState.userDetails);
  const accom = useSelector((state) => state.tripState.accommodation);
  const price = useSelector((state) => state.tripState.price);
  const addtionalPrice = useSelector((state) => state.tripState.addtionalPrice);
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [res, setRes] = React.useState({ discount: 0 });

  const couponAction = (response) => (dispatch) => {
    dispatch({
      type: SET_COUPON,
      payload: { coupon: code, discount: response.data?.discount },
    });
  };
  const clearCode = () => (dispatch) => {
    dispatch({
      type: CLEAR_CODE,
      payload: { coupon: "no_coupon", discount: 0 },
    });
  };

  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  const today = new Date();

  const { t } = useTranslation();

  const applyCoupon = () => {
    setLoading(true);
    Axios.get(
      `https://farahnilecruise.com/backend/en/api/apply-coupon/${code}/${trip?.id}/${user?.mobile}`,
    )
      .then((response) => {
        setRes(response.data);
        dispatch(couponAction(response));
        setErr(false);
      })
      .catch((error) => setErr(error.response))
      .finally(() => setLoading(false));
  };

  const myPercentage = 100 - res?.discount;

  const renderGuests = () => {
    return (
      accom &&
      accom.map((accommodation) => {
        return (
          <>
            <div className="rslt-img text-center">
              <img src={accommodation.img} className="final-img" alt="" />
            </div>
            <div className="rslt-info">
              <h3>{`${accommodation.type === 1 ? "Cabin" : "Suite"} ${
                accommodation.accommodation_num
              }`}</h3>
              <h5>
                <span>Guest: </span>
                {accommodation.guest_name}
              </h5>
            </div>
          </>
        );
      })
    );
  };

  React.useEffect(() => {
    setCode("");
    dispatch(clearCode());
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="final-step">
        <div className="col-md-12">
          <h3>{t("Reservation Details")}</h3>
        </div>
        <div className="col-md-6 col-xs-12 text-left">
          <h5 className="m-b-0">{t("Arrival")}</h5>
          <h4>
            <strong>{trip && trip.check_in}</strong>
          </h4>
          <h5 className="m-b-0">{t("Departure")}</h5>
          <h4>
            <strong>{trip && trip.check_out}</strong>
          </h4>
          <h5 className="m-b-0">{t("Booking Day")}</h5>
          <h4>
            <strong>{today.toLocaleDateString("en-US", options)}</strong>
          </h4>
          <h5 className="m-b-0">{t("Booking Time")}</h5>
          <h4>
            <strong>{today.toLocaleTimeString()}</strong>
          </h4>
        </div>
        <div className="col-md-6 col-xs-12 text-right">
          <h5 className="m-b-0">{t("Client")}</h5>
          <h4>
            <strong>{`${user && user.first_name} ${user && user.last_name}`}</strong>
          </h4>
          <h5 className="m-b-0">{t("Email")}</h5>
          <h4>
            <strong>{user && user.email}</strong>
          </h4>
          <h5 className="m-b-0">{t("Telephone")}</h5>
          <h4>
            <strong>{user && user.mobile}</strong>
          </h4>
        </div>
        <div className="clearfix" />
        <hr className="m-t-25 m-b-25" />
        <div className="clearfix" />
        <div className="col-md-12">
          <div className="results">
            <div className="result-box">
              <div className="result-box">
                <h4 className="m-t-15" style={{ marginLeft: 15 }}>
                  <strong>{t("Booked Cabins")}</strong>
                </h4>
                {renderGuests()}
              </div>
            </div>
            <div className="clearfix" />
            <div className="coupon-div">
              <input
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter your coupon code"
              />
              <button
                style={{ width: "100px" }}
                disabled={res.discount !== 0 ? true : false}
                onClick={() => applyCoupon()}
                className="btn-md-1"
                type="button">
                {res.discount === 0 ? (
                  loading ? (
                    <BiLoaderAlt className="loading-icon" />
                  ) : (
                    "Apply"
                  )
                ) : (
                  <AiFillCheckCircle />
                )}
              </button>
            </div>
            {err === false ? null : (
              <p style={{ color: "red", marginTop: "-20px", marginLeft: "20px" }}>Invalid Code</p>
            )}
          </div>
        </div>
        <div className="clearfix" />
        <hr className="m-t-25 m-b-25" />
        <div className="clearfix" />
        <div className="col-md-12 text-right">
          <h3>
            {res.discount === 0 ? (
              <strong>
                {t("Total")}: ${((price + addtionalPrice) * myPercentage) / 100}
              </strong>
            ) : (
              <strong>
                {t("Total")}: ${((price + addtionalPrice) * myPercentage) / 100}{" "}
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "#888",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}>
                  {price + addtionalPrice}
                </span>
              </strong>
            )}
          </h3>
          <h5>{t("including Taxes")}</h5>
        </div>
      </div>
      <ul className="list-inline pull-left">
        <li>
          <button
            type="button"
            className="btn btn-primary btn-md-1 prev-step"
            onClick={() => setStep(2)}>
            {t("Previous")}
          </button>
        </li>
      </ul>
    </>
  );
};

CompleteReservation.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default function repeatCarousel(sliderArr) {
  // the number 5 means the number of items in largest screen

  let arr = [];
  if (sliderArr.length < 5 && sliderArr.length !== 0) {
    let n = 0;
    for (let i = 0; i < 5; i += 1) {
      if (sliderArr[i]) {
        arr.push(sliderArr[n]);
        n += 1;
      } else {
        n = 0;
        arr.push(sliderArr[n]);
        n += 1;
      }
    }
  } else {
    arr = sliderArr;
  }
  return arr;
}

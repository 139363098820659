import Axios from "axios";
import _ from "lodash";
import { makeUseAxios } from "axios-hooks";
import LRU from "lru-cache";
import { store } from "../redux/store";
import { baseUrl } from "../utils/config";

const toFormData = (data) => {
  const formData = new FormData();
  _.forOwn(data, (value, key) => {
    if (typeof value !== "undefined") {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i += 1) {
          formData.append(`${key}[]`, value[i]);
        }
      } else {
        formData.append(key, value);
      }
    }
  });
  return formData;
};

let apiUrl = `${baseUrl}/en/api`;

const storage = localStorage["persist:root"];

const locale = storage ? JSON.parse(JSON.parse(storage).langState).locale : null;

if (locale) {
  apiUrl = `${baseUrl}/${locale}/api`;
}

// store.subscribe(() => {
//   const state = store.getState();
//   const { locale } = state.langState;
//   apiUrl = `${baseUrl}/${locale}/api`;
// });

const axios = Axios.create({
  baseURL: apiUrl,
  // timeout: 10000,
  headers: {
    Accept: "application/json",
  },
  // transform request data obj to FormData
  transformRequest: [(data) => toFormData(data)],
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const cache = new LRU({ max: 20 });

const useAxios = makeUseAxios({
  axios,
  cache,
});

export default {
  axios,
  useAxios,
  toFormData,
};

import { combineReducers } from "redux";
import langState from "./lang";
import tripState from "./trip";
import checkoutState from "./checkout";
import couponReducer from "./coupon";

export default combineReducers({
  langState,
  tripState,
  checkoutState,
  coupon: couponReducer,
});

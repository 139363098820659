/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FsLightbox from "fslightbox-react";
import convertImgName from "../../services/convertImgName";

export const SingleGallery = ({ galleryPhoto, type }) => {
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(0);

  useEffect(() => {
    const count = productIndex + 1;
    setProductIndex(count);
  }, [galleryPhoto]);

  const items = galleryPhoto.gallery_photos.map((item) => {
    return type === "photos" ? item.photo : item.url;
  });

  function youtubeParser(url) {
    if (url != null) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    }
    return "";
  }

  console.log(youtubeParser("youtube.com/watch?v=CByhaGnB9Co"));

  return (
    <>
      <div
        className={`gallery-box box121 ${
          (galleryPhoto.id === 11 && "item-4") || (galleryPhoto.id === 12 && "item-8")
        }`}
        key={galleryPhoto.id}>
        {type === "photos" ? (
          <img
            src={galleryPhoto.gallery_photos[0]?.photo}
            alt={
              galleryPhoto.gallery_photos[0] && convertImgName(galleryPhoto.gallery_photos[0].photo)
            }
            className="gallery-item box122"
          />
        ) : (
          // <iframe
          //   title="media"
          //   width="560"
          //   height="315"
          //   src={`https://i3.ytimg.com/vi/${youtubeParser(
          //     galleryPhoto.gallery_photos[0]?.url,
          //   )}/maxresdefault.jpg`}
          //   frameBorder="0"
          //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          //   allowFullScreen
          // />
          <img
            alt=""
            src={`https://i3.ytimg.com/vi/${youtubeParser(
              galleryPhoto.gallery_photos[0]?.url,
            )}/maxresdefault.jpg`}
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
              objectPosition: "center center",
            }}
          />
        )}
        <div
          className="gallery-name"
          onClick={() => galleryPhoto.gallery_photos.length !== 0 && setToggler(!toggler)}>
          {galleryPhoto.name}
        </div>
        <div className="img-target">
          <FsLightbox
            toggler={toggler}
            type={type === "photos" ? "image" : "youtube"}
            sources={items}
            key={productIndex}
          />
        </div>
      </div>
    </>
  );
};

SingleGallery.propTypes = {
  galleryPhoto: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    gallery_photos: PropTypes.arrayOf({}),
  }).isRequired,
  type: PropTypes.string.isRequired,
};

import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import _ from "lodash";
import { Header, Spinner } from "../../components";
import { contactUsSchema } from "../../utils/validationSchema";
import api from "../../services/api";
import convertImgName from "../../services/convertImgName";

export const ContactUs = () => {
  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(contactUsSchema) });

  const [{ loading: contactUsLoading }, refetch] = api.useAxios(
    { url: "/contactus", method: "post" },
    { manual: true },
  );

  const [{ data: infoData, loading: infoLoading }] = api.useAxios("/infos");

  const onSubmit = (data) => {
    refetch({ data }).then(() => {
      Swal.fire({
        title: t("Success!"),
        text: t("Your message has been sent successfully!"),
        icon: "success",
        confirmButtonText: "Ok",
      });
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Spinner isOpen={contactUsLoading || infoLoading} />
      <div className="container">
        <div className="section-name m-t-150">
          <span className="desc-text">{t("Contact Us")}</span>
          <br />
          {t("CONTACT FARAH")}
          <br />
        </div>
        <div className="section featured m-b-50 ">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 col-xs-12 wow animated fadeInLeft p-t-20">
              <h5 className="small-title text-center">{t("we will get back to you")}</h5>
              <h2 className="main-title text-center">{t("Leave us your Message")}</h2>
              <form action="#" className="cnct-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("Name")}
                    name="name"
                    ref={register}
                  />
                  {errors.name && <div className="text-danger">{errors.name.message}</div>}
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    placeholder={t("Email")}
                    name="email"
                    ref={register}
                  />
                  {errors.email && <div className="text-danger">{errors.email.message}</div>}
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder={t("Mobile")}
                    name="mobile"
                    ref={register}
                  />
                  {errors.mobile && <div className="text-danger">{errors.mobile.message}</div>}
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder={t("Your Message")}
                    name="message"
                    ref={register}
                  />
                  {errors.message && <div className="text-danger">{errors.message.message}</div>}
                </div>
                <div className="form-group">
                  <button className="form-control btn-new1" type="submit">
                    {t("Send")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <>
        <div className="section facilitis">
          <div className="container">
            <h2 className="main-title text-center  m-b-30">{t("Contact Information")}</h2>
            <div className="row row-center">
              <hr className="m-b-50 m-t-50" />
              <div className="col-md-6 col-xs-12 wow animated fadeInLeft sight">
                <h5 className="small-title  m-t-50">{t("PLATINUM CRUISE MANAGEMENT")}</h5>
                <h2 className="main-title">
                  {infoData && _.find(infoData.contacts, { id: 11 }).name}
                </h2>
                <div className="main-p">
                  <div className="contact-us-flex">
                    <i className="fa-3x fa fa-map-marker-alt m-b-30 m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "address" }).value}</p>
                  </div>
                  <div className="contact-us-flex">
                    <i className="fa-3x fa fa-phone-volume m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "phone" }).value}</p>
                    <p style={{ marginLeft: "4.7rem" }}>
                      {infoData && _.find(infoData.infos, { key: "emergencyContact1" }).value}
                    </p>
                  </div>
                  <div className="contact-us-flex">
                    <i className=" m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "emergencyContact2" }).value}</p>
                    <p style={{ marginLeft: "3rem" }}>
                      {infoData && _.find(infoData.infos, { key: "emergencyContact3" }).value}
                    </p>
                  </div>
                  <div className="contact-us-flex">
                    <i className=" m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "emergencyContact4" }).value}</p>
                  </div>
                  <div className="contact-us-flex">
                    <i className="fa-3x fa fa-fax m-b-30 m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "fax" }).value}</p>
                  </div>
                  <div className="contact-us-flex">
                    <i className="fa-3x fa fa-envelope m-b-30 m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "email" }).value}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12 wow animated fadeInRight zoom_image single_sightseeing">
                <img
                  src={infoData && _.find(infoData.contacts, { id: 11 }).photo}
                  className="img-responsive shdw"
                  alt={infoData && convertImgName(_.find(infoData.contacts, { id: 11 }).photo)}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="m-b-50 m-t-50" />
      </>
      <>
        <div className="section facilitis">
          <div className="container">
            <div className="row row-center">
              <div className="col-md-6 col-xs-12 wow animated fadeInLeft zoom_image single_sightseeing">
                <img
                  src={infoData && _.find(infoData.contacts, { id: 12 }).photo}
                  className="img-responsive shdw"
                  alt={infoData && convertImgName(_.find(infoData.contacts, { id: 12 }).photo)}
                />
              </div>
              <div className="col-md-6 col-xs-12 wow animated  fadeInRight  p-t-50 sight">
                <h5 className="small-title">{t("PLATINUM CRUISE MANAGEMENT")}</h5>
                <h2 className="main-title">
                  {infoData && _.find(infoData.contacts, { id: 12 }).name}
                </h2>
                <p className="main-p">
                  <div className="contact-us-flex">
                    <i className="fa-3x fa fa-map-marker-alt m-b-30 m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "adress-dubai" }).value}</p>
                  </div>
                  <div className="contact-us-flex">
                    <i className="fa-3x fa fa-phone-volume m-b-30 m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "phone-dubai1" }).value}</p>
                    <p style={{ marginLeft: "3rem" }}>
                      {infoData && _.find(infoData.infos, { key: "phone-dubai2" }).value}
                    </p>
                  </div>
                  <div className="contact-us-flex">
                    <i className="fa-3x fa fa-envelope m-b-30 m-r-50 icon-wd" />
                    <p>{infoData && _.find(infoData.infos, { key: "email-dubai" }).value}</p>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-b-50 m-t-50" />
      </>
      <>
        <div className="container cntct-flex">
          <div className="cntct-othrs">
            <h2 className="main-title text-center">
              {infoData && _.find(infoData.contacts, { id: 13 }).name}
            </h2>
            <img
              src={infoData && _.find(infoData.contacts, { id: 13 }).photo}
              alt={infoData && convertImgName(_.find(infoData.contacts, { id: 13 }).photo)}
              className="cntct-othrs-img m-b-50"
            />
            <div className="main-p cntct-othrs-text">
              <div className="contact-us-flex1">
                <i className="fa fa-user m-b-30  icon-wd" />
                <p>{infoData && _.find(infoData.infos, { key: "agent-italy" }).value}</p>
              </div>
              <div className="contact-us-flex1">
                <i className="fa fa-envelope m-b-30  icon-wd" />
                <p>{infoData && _.find(infoData.infos, { key: "email-italy" }).value}</p>
              </div>
              <div className="contact-us-flex1">
                <i className="fa fa-phone-volume m-b-30  icon-wd" style={{ opacity: "0" }} />
              </div>
            </div>
          </div>
          <div className="cntct-othrs">
            <h2 className="main-title text-center">
              {infoData && _.find(infoData.contacts, { id: 14 }).name}
            </h2>
            <img
              src={infoData && _.find(infoData.contacts, { id: 14 }).photo}
              alt={infoData && convertImgName(_.find(infoData.contacts, { id: 14 }).photo)}
              className="cntct-othrs-img m-b-50"
            />
            <div className="main-p cntct-othrs-text">
              <div className="contact-us-flex1">
                <i className="fa fa-user m-b-30  icon-wd" />
                <p>{infoData && _.find(infoData.infos, { key: "agent-spain" }).value}</p>
              </div>
              <div className="contact-us-flex1">
                <i className="fa fa-phone-volume m-b-30  icon-wd" />
                <p>{infoData && _.find(infoData.infos, { key: "phone1-spain" }).value}</p>
                <p style={{ marginLeft: "3rem" }}>
                  {infoData && _.find(infoData.infos, { key: "phone1-spain" }).value}
                </p>
              </div>
              <div className="contact-us-flex1">
                <i className="fa fa-envelope m-b-30  icon-wd" />
                <p>{infoData && _.find(infoData.infos, { key: "email-spain" }).value}</p>
              </div>
            </div>
          </div>
          <div className="cntct-othrs">
            <h2 className="main-title text-center">
              {infoData && _.find(infoData.contacts, { id: 15 }).name}
            </h2>
            <img
              src={infoData && _.find(infoData.contacts, { id: 15 }).photo}
              alt={infoData && convertImgName(_.find(infoData.contacts, { id: 15 }).photo)}
              className="cntct-othrs-img m-b-50"
            />
            <div className="main-p cntct-othrs-text">
              <div className="contact-us-flex1">
                <i className="fa fa-user m-b-30  icon-wd" />
                <p>{infoData && _.find(infoData.infos, { key: "agent-oceania" }).value}</p>
              </div>
              <div className="contact-us-flex1">
                <i className="fa fa-phone-volume m-b-30  icon-wd" />
                <p>{infoData && _.find(infoData.infos, { key: "phone-oceania" }).value}</p>
              </div>
              <div className="contact-us-flex1">
                <i className="fa fa-envelope m-b-30  icon-wd" />
                <p>{infoData && _.find(infoData.infos, { key: "email-oceania" }).value}</p>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-b-50 m-t-50" />
      </>
      <div className="section why-us ">
        <div className="container">
          <h5 className="small-title text-center">{t("Stay in touch")}</h5>
          <h2 className="main-title text-center m-t-50">{t("Boat Contact")}</h2>
          <div className="col-md-6 col-xs-12 wow animated fadeInDown  ">
            <p className="main-p">
              <i className="fa-3x fa fa-phone-volume m-b-30" />
              <br />
              {infoData && _.find(infoData.infos, { key: "phone-boat1" }).value}
              <br />
              {infoData && _.find(infoData.infos, { key: "phone-boat2" }).value}
            </p>
          </div>
          <div className="col-md-6 col-xs-12 wow animated fadeInDown  ">
            <p className="main-p">
              <i className="fa-3x fa fa-envelope m-b-30" />
              <br />
              {infoData && _.find(infoData.infos, { key: "email-boat" }).value}
            </p>
          </div>
        </div>
      </div>
      <div className="section about">
        <div className="container-fluid no-pd">
          <iframe
            src="https://maps.google.com/maps?q=farah%20nile%20&t=&z=17&ie=UTF8&iwloc=&output=embed"
            width="100%"
            height={400}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen
            title="farah's map"
          />
        </div>
      </div>
    </>
  );
};

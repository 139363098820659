import * as yup from "yup";
import i18next from "i18next";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

yup.setLocale({
  mixed: {
    required: () => i18next.t("This field is required"),
    oneOf: () => i18next.t("Email must match"),
  },
  string: {
    email: () => i18next.t("Must be a valid email"),
    matches: () => i18next.t("Phone number is not valid"),
  },
});

export const userSchema = yup.object().shape({
  email: yup.string().required().email(),
  email_verify: yup
    .string()
    .required()
    .email()
    .oneOf([yup.ref("email"), null]),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  mobile: yup.string().required().matches(phoneRegExp),
  country_id: yup.string().required(),
  title: yup.string().required(),
});

export const contactUsSchema = yup.object().shape({
  email: yup.string().required().email(),
  name: yup.string().required(),
  mobile: yup.string().required().matches(phoneRegExp),
  message: yup.string().required(),
});

export const checkSchema = yup.object().shape({
  check_in: yup.string().required(),
  check_out: yup.string().required(),
});

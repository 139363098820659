/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../services/windowDimensions";
import { changeLang } from "../redux/actions";

export const Header = ({ style, tabNo }) => {
  const [activeNav, setActiveNav] = useState(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.langState.locale);
  const { t } = useTranslation();

  const sections = [
    { title: t("Home"), url: "/", id: 1 },
    { title: t("Facilites"), url: "/facilities", id: 2 },
    { title: t("Decks"), url: "/decks", id: 3 },
    { title: t("Itineraries"), url: "/itineraries", id: 4 },
    { title: t("Gallery"), url: "/gallery", id: 5 },
    { title: t("Sightseeing"), url: "/sightseeings", id: 6 },
  ];

  const handleLang = (locale) => {
    if (lang !== locale) {
      const promise = new Promise((resolve) => {
        resolve(dispatch(changeLang(locale)));
      });
      promise.then(() => window.location.reload());
    }
  };

  return (
    <>
      <nav className="nav-bar-header nav-active" style={style}>
        <div className="header-contact">
          <div style={{ flex: 1 }}>
            <img
              src="img/brtn.png"
              style={{ height: "10px", cursor: "pointer", marginRight: "1rem" }}
              alt=""
              onClick={() => handleLang("en")}
            />
            <img
              src="img/prt.png"
              style={{ height: "10px", cursor: "pointer", marginRight: "1rem" }}
              alt=""
              onClick={() => handleLang("pt")}
            />
            <img
              src="img/spn.png"
              style={{ height: "10px", cursor: "pointer", marginRight: "1rem" }}
              alt=""
              onClick={() => handleLang("es")}
            />
          </div>
          <div style={{ flex: 1.7 }} className="pd-res">
            <Link to="/terms" style={{ color: "white", fontSize: "1rem" }}>
              {t("Terms & Conditions")} |
            </Link>
            <Link to="/contact" style={{ color: "white", fontSize: "1rem" }}>
              &nbsp;{t("Contact Us")}
            </Link>
          </div>
        </div>
        <Link to="/" className="logo-res">
          <img src="img/logo-w.png" alt="" style={{ height: "70px" }} />
        </Link>
        <div className={activeNav ? "links-res active-nav" : "links-res"}>
          {sections.map((section) => (
            <Link
              to={section.url}
              style={tabNo === section.id ? { color: "#91989c" } : { color: "white" }}
              key={section.id}>
              <div style={{ position: "relative" }}>
                <div className="header-btns">{section.title}</div>
              </div>
            </Link>
          ))}
        </div>
        {width < 600 && (
          <i
            style={{ color: "#fff" }}
            className={activeNav ? "fas fa-arrow-down rotate-anchor" : "fas fa-arrow-down"}
            onClick={() => setActiveNav(!activeNav)}
          />
        )}
        <Link to="/booking">
          <button className="btn btn-primary btn-md-2 bt2-hover" type="button">
            {t("BOOK NOW")}
          </button>
        </Link>
      </nav>
    </>
  );
};

Header.propTypes = {
  style: PropTypes.shape({}),
  tabNo: PropTypes.number,
};

Header.defaultProps = {
  tabNo: null,
  style: null,
};

import React from "react";
import PropTypes from "prop-types";
import convertImgName from "../../services/convertImgName";

export const Partners = ({ partners }) => {
  const renderPartners = () => {
    return partners.map((item) => (
      <div className="col-md-2">
        <a href={item.url ? item.url : "javascript:void(0)"} target={item.url ? "_blank" : ""}>
          <img
            src={item.photo}
            className="img-responsive prtr-img"
            alt={convertImgName(item.photo)}
          />
        </a>
      </div>
    ));
  };
  return <>{renderPartners()}</>;
};

Partners.propTypes = {
  partners: PropTypes.arrayOf({}).isRequired,
};

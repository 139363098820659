import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { SingleSightSeeing } from "./singleSightSeeing";
import { ScrollToTop } from "../../components";

export const SightSeeingScreen = ({ setStep, setSubStep }) => {
  const addtionalTrip = useSelector((state) => state.tripState.addtionalTrip);

  useEffect(() => {
    if (addtionalTrip.length === 0) {
      setStep(2);
      setSubStep(3);
    }
  }, [addtionalTrip.length, setStep, setSubStep]);

  const validate = () => {
    return !_.find(addtionalTrip, { totalGuest: 0 });
  };

  const handleClick = () => {
    const noNum = validate();
    if (!noNum) {
      Swal.fire({
        title: t("Error!"),
        text: t("Every selected trip must have atleast one person"),
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      setStep(2);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <div className="clearfix" />
      {addtionalTrip.map((trip) => (
        <SingleSightSeeing trip={trip} />
      ))}
      <ul className="list-inline pull-right">
        <li>
          <button
            type="button"
            className="btn btn-primary btn-md-1 next-step"
            onClick={handleClick}>
            {t("Save and continue")}
          </button>
        </li>
      </ul>
      <ul className="list-inline pull-left m-t-50">
        <li>
          <button
            type="button"
            className="btn btn-primary btn-md-1 prev-step"
            onClick={() => setSubStep(3)}>
            {t("Previous")}
          </button>
        </li>
      </ul>
    </>
  );
};

SightSeeingScreen.propTypes = {
  setStep: PropTypes.func.isRequired,
  setSubStep: PropTypes.func.isRequired,
};

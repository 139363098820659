import {
  TRIP,
  USER_DETAILS,
  ACCOMMODATION,
  SET_PRICE,
  SET_CABIN_PRICE,
  SET_SUITE_PRICE,
  SET_CABINS_OPTION,
  SET_SUITES_OPTION,
  SET_GUEST,
  SET_ADDTIONAL_TRIP,
  REMOVE_ADDTIONAL_TRIP,
  SET_ADDTIONAL_PRICE,
  ADULT_COUNT,
  CHILD_COUNT,
  RESET_DATA,
} from "../types";

export const setTripDetails = (payload) => ({
  type: TRIP,
  payload,
});

export const setUserDetails = (payload) => ({
  type: USER_DETAILS,
  payload,
});

export const setAccommodation = (payload) => ({
  type: ACCOMMODATION,
  payload,
});

export const setPrice = (payload) => ({
  type: SET_PRICE,
  payload,
});

export const setCabinPrice = (payload) => ({
  type: SET_CABIN_PRICE,
  payload,
});

export const setSuitePrice = (payload) => ({
  type: SET_SUITE_PRICE,
  payload,
});

export const setCabinsOption = (payload) => ({
  type: SET_CABINS_OPTION,
  payload,
});

export const setSuiteOption = (payload) => ({
  type: SET_SUITES_OPTION,
  payload,
});

export const setGuest = (payload) => ({
  type: SET_GUEST,
  payload,
});

export const setAddtionalTrip = (payload) => ({
  type: SET_ADDTIONAL_TRIP,
  payload,
});

export const removeAddtionalTrip = (payload) => ({
  type: REMOVE_ADDTIONAL_TRIP,
  payload,
});

export const setAddtionalPrice = (payload) => ({
  type: SET_ADDTIONAL_PRICE,
  payload,
});

export const setAdultCount = (payload) => ({
  type: ADULT_COUNT,
  payload,
});

export const setChildCount = (payload) => ({
  type: CHILD_COUNT,
  payload,
});

export const resetData = () => ({
  type: RESET_DATA,
});

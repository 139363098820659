/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { setTripDetails } from "../../redux/actions";
import "react-multi-carousel/lib/styles.css";
import convertImgName from "../../services/convertImgName";
import repeatCarousel from "../../utils/repeatCarousel";

export const RecommendedTrips = ({ tripData }) => {
  const dispatch = useDispatch();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const CustomDot = ({ onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <span className={active ? "dactive dots" : "dots"} />
      </li>
    );
  };

  const { t } = useTranslation();

  const renderTrips = () => {
    return repeatCarousel(tripData.trips).map((trip) => (
      <div className="item recomd-item">
        <a>
          <img src={trip.trip_category.photo} alt={convertImgName(trip.trip_category.photo)} />
        </a>
        <div className="prodct">
          <h3 style={{ height: "50px" }}>{trip.trip_category.name}</h3>
          <h4>{trip.trip_category.duration}</h4>
          <h6 style={{ height: "25px" }}>
            {t("From")}: <strong>${trip.cabin_price}</strong> {trip.trip_category.rate_plan}
          </h6>
          <h6>
            {t("Still Available")}: {trip.cabin_available} {t("Cabin")}&nbsp;&nbsp;
            {trip.suite_available} {t("Suite")}
          </h6>
          <div className="crsl-btn" onClick={() => dispatch(setTripDetails(trip))}>
            <Link to={{ pathname: "/booking", subStep: 2, trip }}>
              <div className="btn btn-new">{t("Book Now")}</div>
            </Link>
          </div>
        </div>
        <div className="trip-date">
          {moment(trip.check_in)
            .format("MMM Do YYYY")
            .split(" ")
            .map((item) => (
              <>
                {item}
                <br />
              </>
            ))}
        </div>
      </div>
    ));
  };
  return (
    <>
      <div className="section-name">
        <span className="desc-text">{t("Recommended")}</span>
        <br />
        {t("Departures")}
        <br />
      </div>
      {tripData && (
        <Carousel
          responsive={responsive}
          showDots
          infinite
          autoPlay
          autoPlaySpeed={2000}
          arrows
          customDot={<CustomDot />}>
          {renderTrips()}
        </Carousel>
      )}
    </>
  );
};

RecommendedTrips.propTypes = {
  tripData: PropTypes.shape({
    trips: PropTypes.arrayOf,
  }).isRequired,
};

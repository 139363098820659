import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

export const Modal = ({ trip, isOpen, setIsOpen }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "solid #615533",
      overflow: "auto",
      height: "500px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      zIndex: 999999999999,
    },
  };
  return (
    <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={() => setIsOpen(false)}>
      <div className="trip-modal">
        <a href="#">
          <img src={trip.img} alt="" />
        </a>
        <div>
          <h3 style={{ textAlign: "center" }}>
            {trip.name} - {trip.location}
          </h3>
          <hr />
        </div>
        <div className="trip-price-modal">
          ${trip.price}
          <br />
          <span style={{ fontSize: "10px" }}>Per Person</span>
        </div>
        <div>{trip.details}</div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  trip: PropTypes.shape({
    img: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    price: PropTypes.string,
    details: PropTypes.string,
  }).isRequired,
};

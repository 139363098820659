/* eslint-disable react/no-danger */
import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { useTranslation } from "react-i18next";
import { Header, Spinner } from "../../components";
import api from "../../services/api";
import { SingleFacility } from "./singleFacility";

export const Facilities = () => {
  const [{ data: facilities, loading: facilitiesLoading }] = api.useAxios({
    url: `/facilities`,
  });

  const [{ data: textData, loading: textLoading }] = api.useAxios("pages/7");

  const renderFacilities = () => {
    return facilities.facilities.map((facility, index) => (
      <SimpleReactLightbox>
        <SingleFacility facility={facility} index={index + 1} key={facility.id} />
      </SimpleReactLightbox>
    ));
  };

  const { t } = useTranslation();

  return (
    <>
      <Header tabNo={2} />
      <Spinner isOpen={facilitiesLoading || textLoading} />
      <div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-12 wow animated fadeInLeft p-t-50">
                <div className="section-name m-t-100 m-b-20 m-res" style={{ textAlign: "center" }}>
                  <span className="desc-text">{t("Farah's")}</span>
                  <br />
                  {t("Facilities")}
                  <br />
                </div>
                {textData && (
                  <div className="main-p" dangerouslySetInnerHTML={{ __html: textData.page }} />
                )}
              </div>
            </div>
            <hr className="m-b-50" />
          </div>
        </div>
        {facilities && renderFacilities()}
      </div>
      {(facilitiesLoading || textLoading) && <div className="fallback" />}
    </>
  );
};

import { CLEAR_CODE, SET_COUPON } from "../types";

const INITIAL_STATE = {
  coupon: {
    coupon: "no_coupon",
    discout: 0,
  },
};

const couponReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_COUPON:
      return { ...state, coupon: action.payload };
    case CLEAR_CODE:
      return { ...state, coupon: action.payload };
    default:
      return state;
  }
};

export default couponReducer;

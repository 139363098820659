/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import FsLightbox from "fslightbox-react";

export const Gallery = ({ homeGallery, type }) => {
  const [toggler, setToggler] = useState(false);

  const activePhoto =
    homeGallery && homeGallery.gallery_photos.filter((item) => item.is_home === 1)[0];

  const images = homeGallery.gallery_photos.map((photo) => {
    return photo.photo;
  });

  return (
    <>
      {homeGallery && (
        <div className={`gallery-box ${type === "stairs" && "item-4"}`}>
          <img src={activePhoto && activePhoto.photo} alt={type} className="gallery-item" />
          <div
            className="gallery-name"
            onClick={() => homeGallery.gallery_photos.length !== 0 && setToggler(!toggler)}>
            {homeGallery && homeGallery.name}
          </div>
          <div className="img-target">
            <FsLightbox toggler={toggler} type="image" sources={images} />
          </div>
        </div>
      )}
    </>
  );
};

Gallery.propTypes = {
  homeGallery: PropTypes.shape({
    name: PropTypes.string,
    gallery_photos: PropTypes.arrayOf({}),
  }).isRequired,
  type: PropTypes.string.isRequired,
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import convertImgName from "../../services/convertImgName";

export const SingleDeck = ({ deck, index, openModal }) => {
  if (index % 2 === 0) {
    return (
      <>
        <div className="section facilitis">
          <div className="container">
            <div className="row row-center">
              <div
                className="col-md-6 col-xs-12 wow animated fadeInLeft p-t-50b sight text-decks"
                style={{ flex: 1 }}>
                <h2 className="main-title">{deck.title}</h2>
                <p className="main-p">
                  <div dangerouslySetInnerHTML={{ __html: deck.content }} />
                </p>
              </div>
              <div
                className="col-md-6 col-xs-12 wow animated fadeInRight zoom_image-1"
                onClick={() => openModal(deck.file)}>
                <img
                  src={deck.file}
                  className="img-responsive shdw"
                  alt={convertImgName(deck.file)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="m-b-50 m-t-50" />
      </>
    );
  }

  return (
    <>
      <div className="section facilitis">
        <div className="container">
          <div className="row row-center">
            <div
              className="col-md-6 col-xs-12 wow animated fadeInRight zoom_image-1"
              onClick={() => openModal(deck.file)}>
              <img
                src={deck.file}
                className="img-responsive shdw"
                alt={convertImgName(deck.file)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              className="col-md-6 col-xs-12 wow animated  fadeInRight  p-t-50 sight text-decks"
              style={{ flex: 1 }}>
              <h2 className="main-title">{deck.title}</h2>
              <p className="main-p">
                <div dangerouslySetInnerHTML={{ __html: deck.content }} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-b-50 m-t-50" />
    </>
  );
};

SingleDeck.propTypes = {
  deck: PropTypes.shape({
    title: PropTypes.string,
    file: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

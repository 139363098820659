import _ from "lodash";
import {
  TRIP,
  USER_DETAILS,
  ACCOMMODATION,
  SET_PRICE,
  SET_CABIN_PRICE,
  SET_SUITE_PRICE,
  SET_CABINS_OPTION,
  SET_SUITES_OPTION,
  SET_GUEST,
  SET_ADDTIONAL_TRIP,
  REMOVE_ADDTIONAL_TRIP,
  SET_ADDTIONAL_PRICE,
  ADULT_COUNT,
  CHILD_COUNT,
  RESET_DATA,
} from "../types";

const INITIAL_STATE = {
  trip: null,
  userDetails: null,
  accommodation: null,
  price: 0,
  cabinPrice: 0,
  suitePrice: 0,
  cabinsOption: 0,
  suiteOption: 0,
  guest: 0,
  addtionalTrip: [],
  addtionalPrice: 0,
  adultCount: 0,
  childCount: 0,
};

export default (state = INITIAL_STATE, action = {}) => {
  let tripArray;
  let exist;

  switch (action.type) {
    case TRIP:
      return { ...state, trip: action.payload };
    case USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case ACCOMMODATION:
      return { ...state, accommodation: action.payload };
    case SET_PRICE:
      return { ...state, price: action.payload };
    case SET_CABIN_PRICE:
      return { ...state, cabinPrice: action.payload };
    case SET_SUITE_PRICE:
      return { ...state, suitePrice: action.payload };
    case SET_CABINS_OPTION:
      return { ...state, cabinsOption: action.payload };
    case SET_SUITES_OPTION:
      return { ...state, suiteOption: action.payload };
    case SET_GUEST:
      return { ...state, guest: action.payload };
    case SET_ADDTIONAL_TRIP:
      tripArray = [...state.addtionalTrip];
      exist = _.findIndex(state.addtionalTrip, { id: action.payload.id });
      if (exist === -1) {
        tripArray.push(action.payload);
      } else {
        tripArray[exist] = action.payload;
      }
      return { ...state, addtionalTrip: tripArray };
    case REMOVE_ADDTIONAL_TRIP:
      tripArray = state.addtionalTrip.filter((trip) => trip.id !== action.payload.id);
      return { ...state, addtionalTrip: tripArray };
    case SET_ADDTIONAL_PRICE:
      return { ...state, addtionalPrice: action.payload };
    case ADULT_COUNT:
      return { ...state, adultCount: action.payload };
    case CHILD_COUNT:
      return { ...state, childCount: action.payload };
    case RESET_DATA:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

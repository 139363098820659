/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";
// import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { resetData, setCheckout } from "../../redux/actions";
import { Spinner } from "../../components";
import api from "../../services/api";

export const TripInfo = ({ setSubStep, step, setStep }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const trip = useSelector((state) => state.tripState.trip);
  const price = useSelector((state) => state.tripState.price);
  const cabinPrice = useSelector((state) => state.tripState.cabinPrice);
  const suitePrice = useSelector((state) => state.tripState.suitePrice);
  const cabinsOption = useSelector((state) => state.tripState.cabinsOption);
  const suiteOption = useSelector((state) => state.tripState.suiteOption);
  const guest = useSelector((state) => state.tripState.guest);
  const addtionalTrip = useSelector((state) => state.tripState.addtionalTrip);
  const addtionalPrice = useSelector((state) => state.tripState.addtionalPrice);
  const accom = useSelector((state) => state.tripState.accommodation);
  const user = useSelector((state) => state.tripState.userDetails);
  const discount = useSelector((state) => state.coupon.coupon.discount);
  const coupon = useSelector((state) => state.coupon.coupon.coupon);

  console.log("COUPON", coupon);

  const [checked, setChecked] = useState(false);

  const [paymentData, setPaymentData] = useState();
  const [runForm, setRunForm] = useState(false);

  const [{ data: infoData, loading: infoLoading }] = api.useAxios("/infos");

  const [{ loading }, refetch] = api.useAxios(
    { url: "/addReservation", method: "post" },
    { manual: true },
  );

  useEffect(() => {
    if (runForm) {
      document.getElementById("paymentForm").submit();
    }
  }, [runForm]);

  const handleClick = () => {
    const accommodations = JSON.stringify(accom);
    const addtionalTrips = JSON.stringify(addtionalTrip);
    const obj = {
      trip_id: trip.id,
      ...user,
      accommodations,
      additional_trips: addtionalTrips,
      coupon,
    };
    refetch({ data: obj }).then(({ data }) => {
      setPaymentData({ ...data.online_payment_data, signature: data.signature });
      dispatch(setCheckout(data.reservation.id));
      // Swal.fire({
      //   title: t("Success!"),
      //   text: t("Submit Reservation Successfully!"),
      //   icon: "success",
      //   confirmButtonText: "Ok",
      // });
    });
  };

  const checkoutForm = () => {
    const fields = [];
    _.forOwn(paymentData, (value, name) => {
      fields.push(<input type="hidden" name={name} value={value} />);
    });
    if (!runForm) {
      setRunForm(true);
    }

    return (
      <form method="POST" action="https://secureacceptance.cybersource.com/pay" id="paymentForm">
        {fields}
      </form>
    );
  };

  const myPercentage = 100 - discount;

  return (
    <div className="trip-details">
      <Spinner isOpen={loading || infoLoading} />

      <div className="trip-info">
        <h3>{trip && trip.trip_category.name}</h3>
        <h4>
          <span>{trip && trip.trip_category.duration}</span>
        </h4>
        {/* <h5>
          <span>Arrival</span>
        </h5>
        <p>{trip && trip.check_in}</p>
        <h5>
          <span>Departure</span>
        </h5> */}
        <p>{trip && trip.check_in}</p>
        <p>{trip && trip.check_out}</p>
        <Link
          to="/booking"
          onClick={() => {
            setSubStep(1);
            dispatch(resetData());
            setStep(1);
          }}>
          {t("Change Date / Trip")}
        </Link>
      </div>
      <div className="trip-prices">
        <p>
          {cabinsOption}x {t("Cabin")}
          <span style={{ float: "right" }}>${cabinPrice}</span>
        </p>
        <p>
          <small>{t("Standard rate (flexible) per cabin/per trip")}</small>
        </p>
        <br />
        <p>
          {suiteOption}x {t("Suite")}
          <span style={{ float: "right" }}>${suitePrice}</span>
        </p>
        <p>
          <small>{t("Standard rate (flexible) per cabin/per trip")}</small>
        </p>
        <br />
        {addtionalTrip.length > 0 && (
          <p>
            <span style={{ color: "#28364b", fontWeight: "bold" }}>
              {t("Addtional Sightseeing")}:{" "}
            </span>
          </p>
        )}
        {addtionalTrip.map((item) => {
          return (
            <>
              <br />
              <p>
                {item.totalGuest}x {item.name}
                <span style={{ float: "right" }}>${item.totalPrice}</span>
              </p>
              <p>
                <small>{t("Standard rate (flexible) per person/per trip")}</small>
              </p>
            </>
          );
        })}
        <hr />
        <p>
          <span>{t("Total")}</span>{" "}
          <span style={{ float: "right" }}>
            ${discount ? ((price + addtionalPrice) * myPercentage) / 100 : price + addtionalPrice}
          </span>
        </p>
        <p>
          <small>
            {t("for")} {guest} {t("guests")}
          </small>
        </p>
      </div>
      {step === 3 && (
        <>
          <div className="accpt">
            <form className="accpt-form">
              <div className="form-group">
                <input
                  name="accept"
                  type="checkbox"
                  defaultValue
                  className="form-control"
                  onChange={({ target }) => setChecked(target.checked)}
                />
                <label>
                  {t("I understand and agree to the property's")}
                  <Link to="/terms">{t("Terms and Conditions")}</Link>
                  {t("as well as the reservation policies")}
                </label>
              </div>
              <button
                type="button"
                className="form-control btn-md-1"
                onClick={() => handleClick()}
                disabled={!checked}>
                {t("CONFIRM RESERVATION")}
              </button>
            </form>
            {paymentData && checkoutForm()}
          </div>
          <div className="cncts">
            <div className="icn">
              <i className="fas fa-mobile-alt" />
            </div>
            <div className="cnct-info">
              <p> {infoData && _.find(infoData.infos, { key: "phone" }).value}</p>
            </div>
            <div className="clearfix m-t-15" />
            <div className="icn">
              <i className="fas fa-map-marker-alt" />
            </div>
            <div className="cnct-info">
              <p>{infoData && _.find(infoData.infos, { key: "address" }).value}</p>
            </div>
            <div className="clearfix m-t-15" />
            <div className="icn">
              <i className="fas fa-envelope-open" />
            </div>
            <div className="cnct-info">
              <p>{infoData && _.find(infoData.infos, { key: "email" }).value}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

TripInfo.propTypes = {
  setSubStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

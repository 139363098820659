/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Header, Spinner } from "../../components";
import api from "../../services/api";
import { SingleGallery } from "./singleGallery";

export const Gallery = () => {
  const { t } = useTranslation();

  const [activeType, setActiveType] = useState("photos");
  const [gallery, setGallery] = useState([]);

  const [{ loading }, refetch] = api.useAxios(
    {
      url: "/gallery",
    },
    { manual: true },
  );

  useEffect(() => {
    refetch({ params: { type: activeType } }).then(({ data }) => {
      setGallery(data.gallery);
    });
  }, [activeType, refetch]);

  const renderGalleyType = () =>
    gallery.map((item) => <SingleGallery galleryPhoto={item} type={activeType} />);

  return (
    <>
      <Header tabNo={5} />
      <Spinner isOpen={loading} />
      <div className="container">
        <div className="section-name gallery-tit">
          <span className="desc-text">{t("Our")}</span>
          <br />
          {t("Gallery")}
          <br />
        </div>
        <div className="gallery-row  m-t-30">
          <div className="galley-select" onClick={() => setActiveType("photos")}>
            <span className={activeType === "photos" ? "gallery-type active-type" : "gallery-type"}>
              {t("Photos")}
            </span>
          </div>
          <div className="galley-select" onClick={() => setActiveType("media")}>
            <span className={activeType === "media" ? "gallery-type active-type" : "gallery-type"}>
              {t("Media")}
            </span>
          </div>
        </div>
        <div className="gallery-grid-1">{gallery.length !== 0 && renderGalleyType()}</div>
      </div>
    </>
  );
};

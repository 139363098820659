import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const SingleAccommodation = ({ num, type, handleAccommodations, clicked }) => {
  const [adultCount, setAdultCount] = useState("0");
  const [childCount, setChildCount] = useState("0");
  const [childOneAge, setChildOneAge] = useState(null);
  const [childTwoAge, setChildTwoAge] = useState(null);

  useEffect(() => {
    if (adultCount !== "0") {
      const accommodation = {
        id: type + num,
        type: type === "Cabin" ? 1 : 2,
        adults_count: adultCount,
        children_count: childCount,
        child1_age: childOneAge,
        child2_age: childTwoAge,
        accommodation_num: null,
        guest_name: null,
        img: null,
      };
      handleAccommodations(accommodation);
    }
  }, [adultCount, childCount, childOneAge, childTwoAge]); // eslint-disable-line

  const onChangeAdults = ({ target }) => {
    const { value } = target;
    setAdultCount(value);
    if (value === "2" && childCount === "2") {
      setChildCount("1");
      setChildTwoAge(null);
    }
  };

  const onChangeChildren = ({ target }) => {
    const { value } = target;
    setChildCount(value);
    if (value === "0") {
      setChildOneAge(null);
      setChildTwoAge(null);
    } else if (value === "1") {
      setChildTwoAge(null);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <div id="red" className="colors m-t-30 single-accommodation">
        <div className="col-md-2 col-xs-12 lft" style={{ marginBottom: "2rem" }}>
          <h4 style={{ marginTop: 15 }}>{`${t(type)} ${num > 9 ? "" : "0"}${num}`}</h4>
        </div>
        <div className="col-md-10 col-xs-12 lft">
          <div className="col-md-3 col-xs-12 lft">
            <label htmlFor="adults-count" style={{ marginBottom: "5px", marginLeft: "15px" }}>
              {t("Adults")}
            </label>
            <select className="cbns" onChange={onChangeAdults} id="adults-count">
              <option value={0}>{t("Adults")}</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            {adultCount === "0" && clicked && (
              <div className="text-danger text-mrg-lft">{t("This field is required")}</div>
            )}
          </div>
          <div className="col-md-3 col-xs-12 lft">
            <label htmlFor="children-count" style={{ marginBottom: "5px", marginLeft: "15px" }}>
              {t("Children")}
            </label>
            <select
              className="cbns"
              onChange={onChangeChildren}
              value={childCount}
              id="children-count">
              <option value={0}>{t("Children")}</option>
              <option value={1}>1</option>
              {adultCount !== "2" && <option value={2}>2</option>}
            </select>
          </div>
          {Number(childCount) > 0 && (
            <>
              <div className="col-md-3 col-xs-12 lft">
                <label htmlFor="child1-count" style={{ marginBottom: "5px", marginLeft: "15px" }}>
                  {t("Child")} 01
                </label>
                <select
                  className="cbns"
                  onChange={(event) =>
                    event.target.value === "0"
                      ? setChildOneAge(null)
                      : setChildOneAge(event.target.value)
                  }
                  id="child1-count"
                  required>
                  <option value={0}>{t("Child")} 01</option>
                  <option value="1">1:5 {t("years")}</option>
                  <option value="2">6:11 {t("years")}</option>
                </select>
                {!childOneAge && clicked && (
                  <div className="text-danger text-mrg-lft">{t("This field is required")}</div>
                )}
              </div>
              {childCount === "2" && adultCount !== "2" && (
                <div className="col-md-3 col-xs-12 lft">
                  <label htmlFor="child2-count" style={{ marginBottom: "5px", marginLeft: "15px" }}>
                    {t("Child")} 02
                  </label>
                  <select
                    className="cbns"
                    onChange={(event) =>
                      event.target.value === "0"
                        ? setChildTwoAge(null)
                        : setChildTwoAge(event.target.value)
                    }
                    id="child2-count">
                    <option value="0">{t("Child")} 02</option>
                    <option value="1">1:5 {t("years")}</option>
                    <option value="2">6:11 {t("years")}</option>
                  </select>
                  {!childTwoAge && clicked && (
                    <div className="text-danger text-mrg-lft">{t("This field is required")}</div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="clearfix" />
    </>
  );
};

SingleAccommodation.propTypes = {
  num: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  handleAccommodations: PropTypes.func.isRequired,
};

/* eslint-disable react/no-danger */
import React from "react";
import { useTranslation } from "react-i18next";
import { Header, Spinner } from "../../components";
import api from "../../services/api";
import { SingleSightSeeing } from "./singleSightSeeing";

export const Sightseeings = () => {
  const [{ data: additionalTripsData, loading: additionalTripsLoading }] = api.useAxios({
    url: `/additionalTrips`,
  });

  const [{ data: textData, loading: textLoading }] = api.useAxios("pages/8");

  const renderTrips = () => {
    return additionalTripsData.additionalTrips.map((trip, index) => (
      <SingleSightSeeing trip={trip} index={index + 1} key={trip.id} />
    ));
  };

  const { t } = useTranslation();

  return (
    <>
      <Header tabNo={6} />
      <Spinner isOpen={additionalTripsLoading || textLoading} />
      <div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-12 wow animated fadeInLeft p-t-50">
                <div className="section-name m-t-100 m-b-20 m-res" style={{ textAlign: "center" }}>
                  <span className="desc-text">{t("Discover")}</span>
                  <br />
                  {t("Egypt's Sightseeings")}
                  <br />
                </div>
                {textData && (
                  <div className="main-p" dangerouslySetInnerHTML={{ __html: textData.page }} />
                )}
              </div>
            </div>
            <hr className="m-b-50" />
          </div>
        </div>
        {additionalTripsData && renderTrips()}
      </div>
      {(additionalTripsLoading || textLoading) && <div className="fallback" />}
    </>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import convertImgName from "../../services/convertImgName";

export const Unique = ({ unique, uniquePhoto }) => {
  const unique1 = _.find(unique, { id: 1 });
  const unique2 = _.find(unique, { id: 2 });
  const unique3 = _.find(unique, { id: 3 });
  const unique4 = _.find(unique, { id: 4 });
  const unique5 = _.find(unique, { id: 5 });
  const unique6 = _.find(unique, { id: 6 });
  const unique7 = _.find(unique, { id: 7 });
  const unique8 = _.find(unique, { id: 8 });

  const { t } = useTranslation();

  return (
    <>
      <div className="section-name" style={{ textAlign: "center" }}>
        <span className="desc-text">{t("Ask for more")}</span>
        <br />
        {t("Benefits")}
        <br />
      </div>
      <div className="unique-container">
        <div className="unique-left">
          <div className="unique-items">
            <div>
              <div className="ftr-box">
                <img src={unique1.photo} alt={convertImgName(unique1.photo)} />
                <div className="unique-text">
                  <h4>{unique1.title}</h4>
                  <p>{unique1.text}</p>
                </div>
              </div>
              <div className="ftr-box">
                <img src={unique2.photo} alt={convertImgName(unique2.photo)} />
                <div className="unique-text">
                  <h4>{unique2.title}</h4>
                  <p>{unique2.text}</p>
                </div>
              </div>
              <div className="ftr-box">
                <img src={unique3.photo} alt={convertImgName(unique3.photo)} />
                <div className="unique-text">
                  <h4>{unique3.title}</h4>
                  <p>{unique3.text}</p>
                </div>
              </div>
              <div className="ftr-box">
                <img src={unique4.photo} alt={convertImgName(unique4.photo)} />
                <div className="unique-text">
                  <h4>{unique4.title}</h4>
                  <p>{unique4.text}</p>
                </div>
              </div>
            </div>
            <div>
              <div className="ftr-box">
                <img src={unique5.photo} alt={convertImgName(unique5.photo)} />
                <div className="unique-text">
                  <h4>{unique5.title}</h4>
                  <p>{unique5.text}</p>
                </div>
              </div>
              <div className="ftr-box">
                <img src={unique6.photo} alt={convertImgName(unique6.photo)} />
                <div className="unique-text">
                  <h4>{unique6.title}</h4>
                  <p>{unique6.text}</p>
                </div>
              </div>
              <div className="ftr-box">
                <img src={unique7.photo} alt={convertImgName(unique7.photo)} />
                <div className="unique-text">
                  <h4>{unique7.title}</h4>
                  <p>{unique7.text}</p>
                </div>
              </div>
              <div className="ftr-box">
                <img src={unique8.photo} alt={convertImgName(unique8.photo)} />
                <div className="unique-text">
                  <h4>{unique8.title}</h4>
                  <p>{unique8.text}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="unique-right" />
        <div className="uniq-img">
          <img src={uniquePhoto} alt={convertImgName(uniquePhoto)} />
        </div>
      </div>
    </>
  );
};

Unique.propTypes = {
  unique: PropTypes.shape({}).isRequired,
  uniquePhoto: PropTypes.string.isRequired,
};

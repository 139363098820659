/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const Itinerary = ({ itineraries }) => {
  const { t } = useTranslation();

  const [day, setDay] = useState(null);

  useEffect(() => {
    if (itineraries.itineraries[0]) {
      setDay(itineraries.itineraries[0].id);
    }
  }, [itineraries]);

  const renderDays = () => {
    return itineraries.itineraries.map((item) => (
      <li
        onClick={() => setDay(item.id)}
        className={day === item.id ? "nav-item active" : "nav-item"}>
        <a className="nav-link cursor">
          {t("DAY")} {item.day}
        </a>
      </li>
    ));
  };

  let programs = [];
  itineraries.itineraries.map((item) => programs.push(...item.itinerary_details));

  programs = programs.filter((item) => item.itinerary_id === day);

  const renderPrograms = () => {
    return programs.map((item) => <li key={item.id}>&gt; {item.text}</li>);
  };

  return (
    <>
      <div className="section facilities text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12 wow animated fadeInLeft ">
              <h5 className="small-title text-center">
                {itineraries.duration} – {itineraries.name}
              </h5>
              <div className="tabsy" id="tabs">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {renderDays()}
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="itineray-content">
                    <ul className="progrm" style={{ flex: 1 }}>
                      {renderPrograms()}
                    </ul>
                    <div className="itineary-map">
                      <img src={itineraries.map} alt="trip-map" className="img-responsive shdw" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="m-t-50 m-b-50" />
        </div>
      </div>
    </>
  );
};

Itinerary.propTypes = {
  itineraries: PropTypes.shape({
    duration: PropTypes.string,
    name: PropTypes.string,
    map: PropTypes.string,
    itineraries: PropTypes.arrayOf({}),
  }).isRequired,
};

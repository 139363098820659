import React, { useState, useEffect } from "react";
import { Transition } from "react-transition-group";
import useWindowPosition from "../services/windowPosition";

const heightScroll = 250;
const duration = 800;

export const GoTop = () => {
  const scrollPosition = useWindowPosition();

  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    if (scrollPosition > heightScroll && !inProp) {
      setInProp(true);
    }
    if (scrollPosition < heightScroll && inProp) {
      setInProp(false);
    }
  }, [scrollPosition]);

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const handleClick = () => {
    window.$("html, body").animate(
      {
        scrollTop: window.$("#root").offset().top,
      },
      800,
    );
  };

  return (
    <>
      {scrollPosition > heightScroll && (
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              onClick={() => handleClick()}
              className="go_top"
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}>
              <i className="fas fa-arrow-up" />
            </div>
          )}
        </Transition>
      )}
    </>
  );
};

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {
  Booking,
  Checkout,
  Home,
  Gallery,
  ContactUs,
  Terms,
  Itineraries,
  Sightseeings,
  Facilities,
  Decks,
} from "./screens";
import { Footer, ScrollToTop, GoTop } from "./components";

function App() {
  const { i18n } = useTranslation();

  const lang = useSelector((state) => state.langState.locale);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <Router>
      <ScrollToTop />
      <GoTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/booking">
          <Booking />
        </Route>
        <Route exact path="/checkout">
          <Checkout />
        </Route>
        <Route exact path="/gallery">
          <Gallery />
        </Route>
        <Route exact path="/contact">
          <ContactUs />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route exact path="/itineraries">
          <Itineraries />
        </Route>
        <Route exact path="/sightseeings">
          <Sightseeings />
        </Route>
        <Route exact path="/facilities">
          <Facilities />
        </Route>
        <Route exact path="/decks">
          <Decks />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { setAccommodation, setCabinsOption, setSuiteOption } from "../../redux/actions";
import { Spinner, ScrollToTop } from "../../components";
import api from "../../services/api";
import useWindowDimensions from "../../services/windowDimensions";

export const FavDecks = ({ setStep, setSubStep }) => {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const accommodations = useSelector((state) => state.tripState.accommodation);
  const trip = useSelector((state) => state.tripState.trip);
  const addtionalTrip = useSelector((state) => state.tripState.addtionalTrip);
  const cabinsOption = useSelector((state) => state.tripState.cabinsOption);
  const suiteOption = useSelector((state) => state.tripState.suiteOption);

  const [{ data, loading }] = api.useAxios({ url: `/getReserved/${trip.id}` });

  const handleChange = ({ target }, type, isLarge) => {
    const arr = [...accommodations];

    if (target.checked) {
      const key = _.findKey(arr, { type, accommodation_num: null });
      if (key) {
        arr[key].accommodation_num = target.value;
        arr[key].img = type === 1 ? "img/room.jpg" : "img/suite.png";
        if (isLarge) {
          arr[key].img = "img/cabin.jpg";
        }
      } else {
        target.checked = false; // eslint-disable-line
        Swal.fire({
          title: t("Error!"),
          text: `${t("You have to select only")} ${cabinsOption} ${t("cabin")}${
            Number(cabinsOption) > 1 ? "s" : ""
          } ${t("and")} ${suiteOption} ${t("suite")} ${Number(suiteOption) > 1 ? "s" : ""}`,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } else {
      const key = _.findKey(arr, { accommodation_num: target.value });
      if (key) {
        arr[key].accommodation_num = null;
        arr[key].img = null;
      }
    }
    dispatch(setAccommodation(arr));
  };

  const renderCheckbox = (num, type, isLarge) => {
    if (!data) {
      return null;
    }
    const exist = _.indexOf(data.reserved, num) !== -1;

    return (
      <>
        {num}
        {exist ? (
          <input type="checkbox" checked disabled />
        ) : (
          <input type="checkbox" value={num} onChange={(e) => handleChange(e, type, isLarge)} />
        )}
      </>
    );
  };

  const validate = () => {
    return !_.find(accommodations, { accommodation_num: null });
  };

  const handleClick = () => {
    const noNum = validate();
    if (!noNum) {
      Swal.fire({
        title: t("Error!"),
        text: t("You have to accomidate all selected cabins"),
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else if (addtionalTrip.length === 0) {
      setStep(2);
    } else {
      setSubStep(4);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Spinner isOpen={loading} />
      <div className="checkses">
        <h3 style={{ fontSize: "20px" }}>{t("Please Choose Your Cabin in Your Favorite Decks")}</h3>
        <div className="decks">
          <div className="col-md-12">
            <h4>{t("WHEEL H.DECK")}</h4>
          </div>
          <div className="col-md-12">
            <div className="btn-group deck_container" data-toggle="buttons">
              {width > 600 && (
                <img src="img/wheel1.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
              <div className="row-dec">
                <div className="wheel_1st_row_cabin">
                  <div className="single_room">{renderCheckbox(425, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(423, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(421, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(419, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(417, 1, false)}
                  </div>
                  <div className="single_room">{renderCheckbox(415, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(413, 1, false)}
                  </div>
                  <div className="single_room">{renderCheckbox(411, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(409, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(407, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(405, 1, true)}
                  </div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(403, 1, true)}
                  </div>
                </div>
                <div className="wheel_2nd_row_cabin">
                  <div className="single_room">{renderCheckbox(426, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(424, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(422, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(420, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(418, 1, false)}
                  </div>
                  <div className="single_room">{renderCheckbox(416, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(414, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(412, 1, false)}
                  </div>
                  <div className="single_room">{renderCheckbox(410, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(408, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(406, 1, true)}
                  </div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(404, 1, true)}
                  </div>
                </div>
              </div>
              {width > 600 && (
                <img src="img/wheel2.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
              <div className="row-dec">
                <div className="wheel_1st_row_suite">
                  <div className="single_room deck_401">{renderCheckbox(401, 2, false)}</div>
                </div>
                <div className="wheel_2nd_row_suite">
                  <div className="single_room deck_402">{renderCheckbox(402, 2, false)}</div>
                </div>
              </div>
              {width > 600 && (
                <img src="img/wheel3.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <hr />
        <div className="decks">
          <div className="col-md-12">
            <h4>{t("SECOND DECK")}</h4>
          </div>
          <div className="col-md-12">
            <div className="btn-group deck_container" data-toggle="buttons">
              {width > 600 && (
                <img src="img/second1.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
              <div className="row-dec">
                <div className="second_1st_row_cabin">
                  <div className="single_room">{renderCheckbox(325, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(323, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(321, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(319, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(317, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(315, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(313, 1, false)}
                  </div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(311, 1, false)}
                  </div>
                  <div className="single_room">{renderCheckbox(309, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(307, 1, true)}
                  </div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(305, 1, true)}
                  </div>
                </div>
                <div className="second_2nd_row_cabin">
                  <div className="single_room">{renderCheckbox(326, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(324, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(322, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(320, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(318, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(316, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(314, 1, false)}
                  </div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(312, 1, false)}
                  </div>
                  <div className="single_room">{renderCheckbox(310, 1, false)}</div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(308, 1, true)}
                  </div>
                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(306, 1, true)}
                  </div>
                </div>
              </div>
              {width > 600 && (
                <img src="img/second2.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
              <div className="row-dec">
                <div className="second_1st_row_suite">
                  <div className="single_room">{renderCheckbox(301, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(303, 1, false)}</div>
                </div>
                <div className="second_2nd_row_suite">
                  <div className="single_room">{renderCheckbox(302, 1, false)}</div>

                  <div className="single_room">{renderCheckbox(304, 1, false)}</div>
                </div>
              </div>
              {width > 600 && (
                <img src="img/second3.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <hr />
        <div className="decks">
          <div className="col-md-12">
            <h4>{t("MAIN DECK")}</h4>
          </div>
          <div className="col-md-12">
            <div className="btn-group deck_container" data-toggle="buttons">
              {width > 600 && (
                <img src="img/main1.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
              <div className="row-dec">
                <div className="main_1st_row_suite">
                  <div className="single_room">{renderCheckbox(202, 1, false)}</div>

                  <div className="single_room">{renderCheckbox(204, 1, false)}</div>

                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(206, 1, true)}
                  </div>
                </div>
                <div className="main_2nd_row_suite">
                  <div className="single_room">{renderCheckbox(201, 1, false)}</div>

                  <div className="single_room">{renderCheckbox(203, 1, false)}</div>

                  <div className="single_room" style={{ backgroundColor: "#ed8c2d" }}>
                    {renderCheckbox(205, 1, true)}
                  </div>
                </div>
              </div>
              {width > 600 && (
                <img src="img/main2.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <hr />
        <div className="decks">
          <div className="col-md-12">
            <h4>{t("LOWER DECK")}</h4>
          </div>
          <div className="col-md-12">
            <div className="btn-group deck_container" data-toggle="buttons">
              {width > 600 && (
                <img src="img/lower1.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
              <div className="row-dec">
                <div className="main_1st_row_suite">
                  <div className="single_room">{renderCheckbox(102, 1, false)}</div>

                  <div className="single_room">{renderCheckbox(104, 1, false)}</div>

                  <div className="single_room">{renderCheckbox(106, 1, false)}</div>
                </div>
                <div className="main_2nd_row_suite">
                  <div className="single_room">{renderCheckbox(101, 1, false)}</div>

                  <div className="single_room">{renderCheckbox(103, 1, false)}</div>
                  <div className="single_room">{renderCheckbox(105, 1, false)}</div>
                </div>
              </div>
              {width > 600 && (
                <img src="img/lower2.jpg" className="img-responsive img-ship" alt="deck1" />
              )}
            </div>
          </div>
          <div className="clearfix" />
          <div className="m-t-50">
            <div className="all-rooms">
              <span className="royal" />
              &nbsp;{t("Royal Suite")}
            </div>
            <div className="all-rooms">
              <span className="double" />
              &nbsp;{t("Cabin - Double")}
            </div>
            <div className="all-rooms">
              <span className="large" />
              &nbsp;{t("Cabin - Large Bed")}
            </div>
          </div>
        </div>
        <ul className="list-inline pull-right">
          <li>
            <button
              type="button"
              className="btn btn-primary btn-md-1 next-step"
              onClick={handleClick}>
              {t("Save and continue")}
            </button>
          </li>
        </ul>
        <ul className="list-inline pull-left m-t-50">
          <li>
            <button
              type="button"
              className="btn btn-primary btn-md-1 prev-step"
              onClick={() => {
                setSubStep(2);
                dispatch(setCabinsOption("0"));
                dispatch(setSuiteOption("0"));
                dispatch(setAccommodation(null));
              }}>
              {t("Previous")}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

FavDecks.propTypes = {
  setStep: PropTypes.func.isRequired,
  setSubStep: PropTypes.func.isRequired,
};

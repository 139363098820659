import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { userSchema } from "../../utils/validationSchema";
import { setAccommodation, setUserDetails } from "../../redux/actions";
import api from "../../services/api";
import { ScrollToTop, Spinner } from "../../components";

export const UserDetails = ({ setStep, step }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const accommodations = useSelector((state) => state.tripState.accommodation);

  const [{ data: countryData, loading }, refetch] = api.useAxios(
    { url: "/countries" },
    { manual: true },
  );

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (step === 2) {
      refetch();
    }
  }, [step, refetch]);

  const handleChange = ({ target }, num) => {
    const arr = [...accommodations];

    const key = _.findKey(arr, { accommodation_num: num });
    if (key) {
      arr[key].guest_name = target.value;
    } else {
      Swal.fire({
        title: t("Error!"),
        text: t("Invalid Accommodation!"),
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    dispatch(setAccommodation(arr));
  };

  const onSubmit = (data) => {
    dispatch(setUserDetails(data));
    setStep(3);
  };

  const renderGuests = () => {
    return accommodations.map((accommodation) => {
      return (
        <div className="results">
          <div className="result-box">
            <div className="rslt-img">
              <img src={accommodation.img} alt="cabin" />
            </div>
            <div className="rslt-info">
              <h3>{`${accommodation.type === 1 ? t("Cabin") : t("Suite")} ${
                accommodation.accommodation_num
              }`}</h3>
              <h5>
                ({Number(accommodation.adults_count) + Number(accommodation.children_count)} people)
              </h5>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("Guest Name")}
                  required
                  ref={register}
                  name={accommodation.accommodation_num}
                  onChange={(e) => handleChange(e, accommodation.accommodation_num)}
                />
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      );
    });
  };

  return (
    <>
      <Spinner isOpen={loading} />
      <ScrollToTop />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="enter-details">
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <select className="cbns fully" ref={register} name="title">
                <option value="">{t("Title")}</option>
                <option value="Mr.">{t("Mr.")}</option>
                <option value="Ms.">{t("Ms.")}</option>
              </select>
              {errors.title && <div className="text-danger">{errors.title.message}</div>}
            </div>
          </div>
          <div className="clearfix m-t-15" />
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder={t("First Name")}
                ref={register}
                name="first_name"
              />
              {errors.first_name && <div className="text-danger">{errors.first_name.message}</div>}
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder={t("Last Name")}
                ref={register}
                name="last_name"
              />
              {errors.last_name && <div className="text-danger">{errors.last_name.message}</div>}
            </div>
          </div>
          <div className="clearfix m-t-15" />
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder={t("Email")}
                ref={register}
                name="email"
              />
              {errors.email && <div className="text-danger">{errors.email.message}</div>}
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder={t("Email (Verify)")}
                ref={register}
                name="email_verify"
              />
              {errors.email_verify && (
                <div className="text-danger">{errors.email_verify.message}</div>
              )}
            </div>
          </div>
          <div className="clearfix m-t-15" />
          <div className="col-md-12 col-xs-12">
            <p>
              {t(
                "Please make sure your email is correct, this where you will receive your final reservation confirmation.",
              )}
            </p>
          </div>
          <div className="clearfix m-t-15" />
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder={t("Mobile")}
                ref={register}
                name="mobile"
              />
              {errors.mobile && <div className="text-danger">{errors.mobile.message}</div>}
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <select className="cbns fully" ref={register} name="country_id">
                <option value="">{t("Country")}</option>
                {countryData &&
                  countryData.countries.map((country) => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))}
              </select>
              {errors.country && <div className="text-danger">{errors.country.message}</div>}
            </div>
          </div>
        </div>
        <div className="clearfix" />
        {accommodations && accommodations.length !== 0 && renderGuests()}
        <div className="clearfix" />
        <div className="col-md-12">
          <div className="form-group">
            <textarea
              placeholder={t("your Reservation Comment")}
              className="form-control"
              defaultValue=""
              ref={register}
              name="comment"
            />
          </div>
        </div>
        <div className="clearfix" />
        <ul className="list-inline pull-right">
          <li>
            <button
              type="button"
              className="btn btn-primary btn-md-1 prev-step prv-top"
              onClick={() => setStep(1)}>
              {t("Previous")}
            </button>
          </li>
          <li>
            <button type="submit" className="btn btn-primary btn-md-1 next-step">
              {t("Save and continue")}
            </button>
          </li>
        </ul>
        <div className="clearfix" />
      </form>
    </>
  );
};

UserDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

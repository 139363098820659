import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { removeAddtionalTrip, setAddtionalTrip } from "../../redux/actions";
import convertImgName from "../../services/convertImgName";

export const SingleSightSeeing = ({ trip }) => {
  const [adultCount, setAdultCount] = useState(0);
  const [child1Count, setChild1Count] = useState(0);
  const [child2Count, setChild2Count] = useState(0);
  const adultGuest = useSelector((state) => state.tripState.adultCount);
  const childGuest = useSelector((state) => state.tripState.childCount);

  const dispatch = useDispatch();

  useEffect(() => {
    let totalGuest = 0;
    totalGuest += Number(adultCount) + Number(child1Count) + Number(child2Count);

    const { price, single_price } = trip;
    const childrenPrice = price * 0.5 * Number(child1Count);
    const totalPrice =
      adultCount == 1
        ? single_price * Number(adultCount) + childrenPrice
        : price * Number(adultCount) + childrenPrice;

    dispatch(
      setAddtionalTrip({ ...trip, totalGuest, totalPrice, adultCount, child1Count, child2Count }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adultCount, child1Count, child2Count]);

  const renderOptions = (count) => {
    const arr = [];
    for (let i = 0; i <= count; i += 1) {
      arr.push(
        <option value={i} key={i}>
          {i}
        </option>,
      );
    }
    return arr;
  };

  const { t } = useTranslation();

  return (
    <div className="results sight-seeing">
      <div className="result-box">
        <div className="rslt-img">
          <img src={trip.img} alt={convertImgName(trip.img)} />
        </div>
        <div className="rslt-info">
          <h3>
            {t("Trip Name")}: {trip.name}
          </h3>
          <h4>
            <span>
              {t("Location")}: {trip.location}
            </span>
          </h4>
          <h5>
            {t("Price")}: ${trip.price}
          </h5>
          <a
            className="btn btn-new"
            style={{ color: "white", backgroundColor: "red", width: "60%", fontSize: "10px" }}
            onClick={() => dispatch(removeAddtionalTrip(trip))}>
            {t("Remove Trip")}
          </a>
        </div>
      </div>
      <div className="sight-seeing-select formy">
        <div style={{ width: "100%" }}>
          <label htmlFor="adults-count" className="m-b-10">
            {t("Adults")}
          </label>
          <select
            id="adults-count"
            className="cbns"
            onChange={({ target }) => setAdultCount(target.value)}>
            {renderOptions(adultGuest)}
          </select>
        </div>
        <div style={{ width: "100%" }}>
          <label htmlFor="child1-count" className="m-b-10">
            {t("Children")} 6:12
          </label>
          <select
            id="child1-count"
            className="cbns"
            onChange={({ target }) => setChild1Count(target.value)}>
            {renderOptions(childGuest - child2Count)}
          </select>
        </div>
        <div style={{ width: "100%" }}>
          <label htmlFor="child2-count" className="m-b-10">
            {t("Children")} 1:5
          </label>
          <select
            id="child2-count"
            className="cbns"
            onChange={({ target }) => setChild2Count(target.value)}>
            {renderOptions(childGuest - child1Count)}
          </select>
        </div>
      </div>
      <div className="clearfix" />
    </div>
  );
};

SingleSightSeeing.propTypes = {
  trip: PropTypes.shape({
    img: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    price: PropTypes.string,
    details: PropTypes.string,
  }).isRequired,
};

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { setCheckout } from "../../redux/actions";
import { Header, Spinner } from "../../components";
import api from "../../services/api";

export const Checkout = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const reservationId = useSelector((state) => state.checkoutState.reservationId);

  const [{ data, loading }, refetch] = api.useAxios(
    { url: `/getCheckout/${reservationId}` },
    { manual: true },
  );

  useEffect(() => {
    if (reservationId) {
      refetch();
    }
  }, [reservationId]); // eslint-disable-line

  const checkoutForm = () => {
    if (data.reservation.status === 1) {
      dispatch(setCheckout(null));
      return null;
    }
    if (data.reservation.status === 0) {
      return null;
    }
    const fields = [];
    _.forOwn({ ...data.online_payment_data, signature: data.signature }, (value, name) => {
      fields.push(<input type="hidden" name={name} value={value} />);
    });

    return (
      <form method="POST" action="https://secureacceptance.cybersource.com/pay" id="paymentForm">
        {fields}
        <button type="submit" className="form-control btn-md-1 btn-checkout">
          {t("CONFIRM RESERVATION")}
        </button>
      </form>
    );
  };

  const renderGuests = (accommodations) => {
    return accommodations.map((accommodation) => {
      return (
        <div key={accommodation.id}>
          <div className="rslt-img text-center">
            <img
              src={accommodation.type === 1 ? "img/room.jpg" : "img/suite.png"}
              className="final-img"
              alt=""
            />
          </div>
          <div className="rslt-info">
            <h3>{`${accommodation.type === 1 ? "Cabin" : "Suite"} ${
              accommodation.accommodation_num
            }`}</h3>
            <h5>
              <span>Guest: </span>
              {accommodation.guest_name}
            </h5>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Header />
      <Spinner isOpen={loading} />
      {data ? (
        <div className="container resrv-section">
          <div className="row m-t-150 m-b-50">
            <div
              className={`alert alert-${data.reservation.status === 1 ? "success" : "danger"}`}
              role="alert"
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                {data.reservation.status !== null ? data.reservation.payment_res_msg : "try again"}
              </div>
              {checkoutForm()}
            </div>
            <div className="section-name m-b-10 m-res" style={{ textAlign: "center" }}>
              <br />
              {t("Reservation Details")}
              <br />
            </div>
            <div className="col-md-12 col-xs-12">
              <section>
                <div className="final-step">
                  <div style={{ display: "flex" }}>
                    <div className="col-md-6 col-xs-12">
                      <h5 className="m-b-0">{t("Arrival")}</h5>
                      <h4>
                        <strong>{data.reservation.trip.check_in}</strong>
                      </h4>
                      <h5 className="m-b-0">{t("Departure")}</h5>
                      <h4>
                        <strong>{data.reservation.trip.check_out}</strong>
                      </h4>
                      <h5 className="m-b-0">{t("Booking Day")}</h5>
                      <h4>
                        <strong>{data.reservation.created_at}</strong>
                      </h4>
                      <h5 className="m-b-0">{t("Booking Time")}</h5>
                      <h4>
                        <strong>{data.reservation.updated_at}</strong>
                      </h4>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <h5 className="m-b-0">{t("Client")}</h5>
                      <h4>
                        <strong>{`${data.reservation.user.first_name} ${data.reservation.user.last_name}`}</strong>
                      </h4>
                      <h5 className="m-b-0">{t("Email")}</h5>
                      <h4>
                        <strong>{data.reservation.user.email}</strong>
                      </h4>
                      <h5 className="m-b-0">{t("Telephone")}</h5>
                      <h4>
                        <strong>{data.reservation.user.mobile}</strong>
                      </h4>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <hr className="m-t-25 m-b-25" />
                  <div className="clearfix" />
                  <div className="col-md-12">
                    <div className="results">
                      <div className="result-box">
                        <div className="result-box">
                          <h4 className="m-t-15" style={{ marginLeft: 15 }}>
                            <strong>{t("Booked Cabins")}</strong>
                          </h4>
                          {renderGuests(data.reservation.accommodations)}
                        </div>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="clearfix" />
                  <hr className="m-t-25 m-b-25" />
                  <div className="col-md-12 text-right">
                    <h3>
                      <strong>
                        {t("Total")}: ${data.reservation.price}
                      </strong>
                    </h3>
                    <h5>{t("including Taxes")}</h5>
                  </div>
                  <div className="clearfix" />
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="fallback"
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Link to="/booking">
            no checkout data:{" "}
            <button
              className="btn btn-primary btn-md-2 bt2-hover"
              type="button"
              style={{ display: "inline" }}>
              {t("BOOK NOW")}
            </button>
          </Link>
        </div>
      )}
    </>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import repeatCarousel from "../../utils/repeatCarousel";

export const TripAdvisor = ({ tripadvisor }) => {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  const CustomDot = ({ onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <span className={active ? "dactive dots" : "dots"} />
      </li>
    );
  };

  const renderTripadvisors = () => {
    return repeatCarousel(tripadvisor).map((item) => (
      <div className="tests">
        <h4>{item.title}</h4>
        <p>{item.text}</p>
        <div className="sgntr">{item.author}</div>
        {/* <a href={item.url} target="_blank" className="view-link">
          {t("View More")}
        </a> */}
      </div>
    ));
  };
  return (
    <>
      <div className="col-md-12 col-xs-12 text-center trip-advisor">
        <img src="img/TripAdvisor-Logo-Small-1.png" width={150} height={85} alt="" />
        <p>People Say About Us</p>
        <div className="row">
          <Carousel
            responsive={responsive}
            showDots
            infinite
            autoPlay
            autoPlaySpeed={2000}
            partialVisible
            arrows
            keyBoardControl
            customDot={<CustomDot />}>
            {renderTripadvisors()}
          </Carousel>
        </div>
      </div>
    </>
  );
};

TripAdvisor.propTypes = {
  tripadvisor: PropTypes.arrayOf({}).isRequired,
};

/* eslint-disable react/no-danger */
import React from "react";
import { useTranslation } from "react-i18next";
import { Header, Spinner } from "../../components";
import { Itinerary } from "./itinerary";
import api from "../../services/api";

export const Itineraries = () => {
  const { t } = useTranslation();

  const [{ data: itinerariesData, loading: itinerariesLoading }] = api.useAxios({
    url: "/itineraries",
  });

  const [{ data: textData, loading: textLoading }] = api.useAxios("pages/5");
  const [{ data: textData2, loading: textLoading2 }] = api.useAxios("pages/10");

  const renderItinerary = () => {
    return itinerariesData.itineraries.map((item) => (
      <Itinerary itineraries={item} key={item.id} />
    ));
  };

  return (
    <>
      <Header tabNo={4} />
      <Spinner isOpen={itinerariesLoading || textLoading || textLoading2} />
      <div>
        <div className="section facilities">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-12 wow animated fadeInLeft p-t-50">
                <div className="section-name m-t-100 m-b-20 m-res" style={{ textAlign: "center" }}>
                  <span className="desc-text">{t("Farah's")}</span>
                  <br />
                  {t("Itineraries")}
                  <br />
                </div>
                {textData && (
                  <div className="main-p" dangerouslySetInnerHTML={{ __html: textData.page }} />
                )}
              </div>
            </div>
          </div>
        </div>
        <hr className="m-b-50" />
        {itinerariesData && renderItinerary()}
        <hr />
        <div className="section facilities m-t-50 m-b-50">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-12 wow animated fadeInLeft ">
                {textData2 && (
                  <div className="main-p" dangerouslySetInnerHTML={{ __html: textData2.page }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(itinerariesLoading || textLoading || textLoading2) && <div className="fallback" />}
    </>
  );
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import FsLightbox from "fslightbox-react";
import convertImgName from "../../services/convertImgName";

export const SingleSightSeeing = ({ trip, index }) => {
  const [toggler, setToggler] = useState(false);

  const images = trip.additional_trips_photos.map((photo) => {
    return photo.photo;
  });

  if (index % 2 === 0) {
    return (
      <>
        <div className="section facilitis">
          <div className="container">
            <div className="row row-center">
              <div className="col-md-6 col-xs-12 wow animated fadeInLeft sight">
                <h2 className="main-title">{trip.name}</h2>
                <p className="main-p">{trip.details}</p>
              </div>
              <div
                className="col-md-6 col-xs-12 wow animated fadeInRight zoom_image single_sightseeing"
                onClick={() => trip.additional_trips_photos.length && setToggler(!toggler)}>
                <img
                  src={trip.img}
                  className="img-responsive shdw"
                  alt={convertImgName(trip.img)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="img-target">
          <FsLightbox toggler={toggler} type="image" sources={images} />
        </div>
        <hr className="m-b-50 m-t-50" />
      </>
    );
  }

  return (
    <>
      <div className="section facilitis">
        <div className="container">
          <div className="row row-center">
            <div
              className="col-md-6 col-xs-12 wow animated fadeInLeft zoom_image single_sightseeing"
              onClick={() => trip.additional_trips_photos.length && setToggler(!toggler)}>
              <img
                src={trip.img}
                className="img-responsive shdw"
                alt={convertImgName(trip.img)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="col-md-6 col-xs-12 wow animated fadeInRight sight">
              <h2 className="main-title">{trip.name}</h2>
              <p className="main-p">{trip.details}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="img-target">
        <FsLightbox toggler={toggler} type="image" sources={images} />
      </div>
      <hr className="m-b-50 m-t-50" />
    </>
  );
};

SingleSightSeeing.propTypes = {
  trip: PropTypes.shape({
    name: PropTypes.string,
    img: PropTypes.string,
    details: PropTypes.string,
    additional_trips_photos: PropTypes.arrayOf({}),
  }).isRequired,
  index: PropTypes.number.isRequired,
};

import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers";
import { checkSchema } from "../../utils/validationSchema";
import api from "../../services/api";
import { Spinner } from "../../components/spinner";

export const CheckAvailability = ({ setSubStep, setTripData }) => {
  const [checkInError, setCheckInError] = useState(null);
  const [checkOutError, setCheckOutError] = useState(null);

  const locale = useSelector((state) => state.langState.locale);

  const [{ data: closedDatesData, loading: loadingClosedDates }] = api.useAxios("/closedDates");

  const [{ loading }, refetch] = api.useAxios({ url: "/trips" }, { manual: true });

  const { register, setValue, handleSubmit, errors } = useForm({
    resolver: yupResolver(checkSchema),
  });

  useEffect(() => {
    register({ name: "check_in" });
    register({ name: "check_out" });
  }, [register]);

  const changeDate = (date, name) => {
    setValue(name, date.format("YYYY-MM-DD HH:mm:ss"));
  };

  const onSubmit = (data) => {
    refetch({ params: { ...data } })
      .then((res) => {
        setCheckInError(null);
        setCheckOutError(null);
        setTripData(res.data.trips);
      })
      .catch((err) => {
        setCheckInError(err.response.data.errors.check_in[0]);
        setCheckOutError(err.response.data.errors.check_out[0]);
      });
  };

  const yesterday = moment().subtract(1, "day");
  const disableDates = (current) => {
    return (
      current.isAfter(yesterday) &&
      closedDatesData.closedDates.indexOf(current.format("YYYY-MM-DD")) !== -1
    );
  };

  const lang = moment.locale(locale);

  const { t } = useTranslation();

  return (
    <>
      <Spinner isOpen={loading || loadingClosedDates} />
      {closedDatesData?.closedDates && (
        <form onSubmit={handleSubmit(onSubmit)} className="check-av1">
          <div className="frm-ctr">
            <Datetime
              onChange={(date) => changeDate(date, "check_in")}
              dateFormat="DD-MM-YYYY"
              timeFormat={null}
              locale={lang}
              isValidDate={disableDates}
              inputProps={{
                placeholder: t("Check in"),
                readOnly: true,
              }}
            />
            {checkInError && <div className="text-danger-check">{checkInError}</div>}
            {errors.check_in && <div className="text-danger-check">{errors.check_in.message}</div>}
          </div>
          <div className="frm-ctr">
            <Datetime
              onChange={(date) => changeDate(date, "check_out")}
              dateFormat="DD-MM-YYYY"
              timeFormat={null}
              locale={lang}
              isValidDate={disableDates}
              inputProps={{
                placeholder: t("Check out"),
                readOnly: true,
              }}
            />
            {checkOutError && <div className="text-danger-check">{checkOutError}</div>}
            {errors.check_out && (
              <div className="text-danger-check">{errors.check_out.message}</div>
            )}
          </div>
          <div className="clearfix" />
          <button
            type="submit"
            className="btn btn-md-2"
            onClick={() => {
              setSubStep(1);
            }}>
            {t("Check Availability")}
          </button>
        </form>
      )}
    </>
  );
};

CheckAvailability.propTypes = {
  setTripData: PropTypes.func.isRequired,
  setSubStep: PropTypes.func.isRequired,
};

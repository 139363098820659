import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Trip, Accommodation, TripInfo, CheckAvailability, Sightseeing } from "./index";
import { UserDetails } from "./userDetails";
import { CompleteReservation } from "./completeReservation";
import { FavDecks } from "./favDecks";
import { Header } from "../../components";
import { SightSeeingScreen } from "./sightSeeingScreen";
import { resetData } from "../../redux/actions";

export const Booking = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [substep, setSubStep] = useState(location.subStep || 1);

  const [tripData, setTripData] = useState(location.data);
  const [singleTrip, setSingleTrip] = useState(location.trip || null);

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, [dispatch]);

  const renderTrips = () => {
    return tripData.map((item) => (
      <Trip setSubStep={setSubStep} data={item} setSingleTrip={setSingleTrip} />
    ));
  };

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="container resrv-section">
        <div className="row m-t-100">
          <div className="col-md-8 col-xs-12">
            <section>
              <div className="wizard">
                <div className="wizard-inner">
                  <ul className="nav nav-tabs" role="tablist">
                    <li
                      role="presentation"
                      className={step === 1 ? "active" : ""}
                      onClick={() => {
                        setSubStep(1);
                        setStep(1);
                      }}>
                      <a
                        href="#step1"
                        data-toggle="tab"
                        aria-controls="step1"
                        role="tab"
                        title="Step 1">
                        1. {t("Select Your Cabins")}{" "}
                        <i className="fas fa-angle-double-right" style={{ color: "#91989c" }} />
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={step === 2 && "active"}
                      onClick={() => step > 1 && setStep(2)}>
                      <a
                        href="#step2"
                        data-toggle="tab"
                        aria-controls="step2"
                        role="tab"
                        title="Step 2"
                        className={step > 1 ? "txt-cent1" : "disabled txt-cent1"}>
                        2. {t("Enter Your Details")}{" "}
                        <i className="fas fa-angle-double-right" style={{ color: "#91989c" }} />
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={step === 3 && "active"}
                      onClick={() => step > 2 && setStep(3)}>
                      <a
                        href="#step3"
                        data-toggle="tab"
                        aria-controls="step3"
                        role="tab"
                        title="Step 3"
                        className={step > 2 ? "txt-cent2" : "disabled txt-cent2"}>
                        3. {t("Complete Reservation")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div role="form" className="formy">
                  <div className="tab-content">
                    <div
                      className={`tab-pane ${step === 1 && "active"}`}
                      role="tabpanel"
                      id="step1">
                      {substep === 1 && (
                        <CheckAvailability setSubStep={setSubStep} setTripData={setTripData} />
                      )}
                      <div className="clearfix" />
                      {/* step1/1 */}
                      {substep === 1 && tripData && tripData.length !== 0 ? (
                        renderTrips()
                      ) : substep === 1 ? (
                        <div className="no-trips">{t("No Availability")}</div>
                      ) : null}
                      <div className="clearfix" />
                      <hr />
                      {/* step1/2 */}
                      {substep === 2 && (
                        <Accommodation
                          numofSuites={singleTrip.suite_available}
                          numofCabins={singleTrip.cabin_available}
                          cabinPrice={singleTrip.cabin_price}
                          suitePrice={singleTrip.suite_price}
                          cabinPriceSingle={singleTrip.single_cabin_price}
                          suitePriceSingle={singleTrip.single_suite_price}
                          setSubStep={setSubStep}
                        />
                      )}
                      {/* step1/3 */}
                      {substep === 3 && <FavDecks setStep={setStep} setSubStep={setSubStep} />}
                      {/* step1/4 */}
                      {substep === 4 && (
                        <SightSeeingScreen setStep={setStep} setSubStep={setSubStep} />
                      )}
                    </div>
                    <div
                      className={`tab-pane ${step === 2 && "active"}`}
                      role="tabpanel"
                      id="step2">
                      <UserDetails setStep={setStep} step={step} />
                    </div>
                    <div
                      className={`tab-pane ${step === 3 && "active"}`}
                      role="tabpanel"
                      id="step3">
                      <CompleteReservation setStep={setStep} />
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="col-md-4 col-xs-12 mrg-info">
            <TripInfo setSubStep={setSubStep} step={step} setStep={setStep} />
          </div>
        </div>
        {substep > 1 && step === 1 && <Sightseeing />}
      </div>
    </>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import loadJS from "../../utils/loadJS";

export const Intro = ({ sliderPhotos }) => {
  useEffect(() => {
    loadJS("/js/jquery.zoomslider.js");
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <div
        id="demo-1"
        data-zs-src={`${JSON.stringify(sliderPhotos)}`}
        data-zs-overlay="dots"
        className="overlay-img">
        <h1 className="text-intro">
          <span className="farah-text">{t("farah nile cruise")}</span>
          {t("A deeper look to one of the most elite")}
          <br />
          {t("Nile Cruises Ships in Egypt")}
        </h1>
      </div>
    </>
  );
};

Intro.propTypes = {
  sliderPhotos: PropTypes.arrayOf({}).isRequired,
};

import { SET_CHECKOUT } from "../types";

const INITIAL_STATE = {
  reservationId: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_CHECKOUT:
      return { ...state, reservationId: action.reservationId };
    default:
      return state;
  }
};

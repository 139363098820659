import React from "react";
import PropTypes from "prop-types";
import convertImgName from "../../services/convertImgName";

export const SingleFacility = ({ facility, index }) => {
  if (index % 2 === 0) {
    return (
      <>
        <div className="section facilitis">
          <div className="container">
            <div className="row row-center">
              <div className="col-md-6 col-xs-12 wow animated fadeInLeft sight">
                <h2 className="main-title">{facility.name}</h2>
                <p className="main-p">{facility.details}</p>
              </div>
              <div className="col-md-6 col-xs-12 wow animated fadeInRight zoom_image single_sightseeing">
                <img
                  src={facility.img}
                  className="img-responsive shdw"
                  alt={convertImgName(facility.img)}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="m-b-50 m-t-50" />
      </>
    );
  }

  return (
    <>
      <div className="section facilitis">
        <div className="container">
          <div className="row row-center">
            <div className="col-md-6 col-xs-12 wow animated fadeInLeft zoom_image single_sightseeing">
              <img
                src={facility.img}
                className="img-responsive shdw"
                alt={convertImgName(facility.img)}
              />
            </div>
            <div className="col-md-6 col-xs-12 wow animated  fadeInRight sight">
              <h2 className="main-title">{facility.name}</h2>
              <p className="main-p">{facility.details}</p>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-b-50 m-t-50" />
    </>
  );
};

SingleFacility.propTypes = {
  facility: PropTypes.shape({
    name: PropTypes.string,
    img: PropTypes.string,
    details: PropTypes.string,
    facility_photos: PropTypes.arrayOf({}),
  }).isRequired,
  index: PropTypes.number.isRequired,
};

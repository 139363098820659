/* eslint-disable react/no-danger */
import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../services/api";
import { Spinner } from "./spinner";

export const Footer = () => {
  const [{ data: infoData, loading: infoLoading }] = api.useAxios("/infos");
  const [{ loading: newsletterLoading }, addNewsletter] = api.useAxios(
    { url: "/newsletter", method: "POST" },
    { manual: true },
  );

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    addNewsletter({ data })
      .then(() => {
        Swal.fire({
          title: t("Success!"),
          text: t("Your email has been saved successfully!"),
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: t("Error!"),
          text: err.response.data.errors.email[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const { t } = useTranslation();

  return (
    <>
      <Spinner isOpen={infoLoading || newsletterLoading} />
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-xs-12 text-center fix-margin">
              <div className="widget">
                <div className="img">
                  <a>
                    <img className="ftr-img" src="img/logo-w.png" alt="farah-logo" />
                  </a>
                </div>
                {infoData && (
                  <p
                    style={{ lineHeight: "30px" }}
                    dangerouslySetInnerHTML={{ __html: infoData.farahMeaning.content }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-4 col-xs-12 text-center fix-margin">
              <div className="widget">
                <h4 className="footer-title">{t("Explore")}</h4>
                <div className="footer-links">
                  <Link to="/" className="single-footer-link">
                    <i className="fa fa-angle-right" style={{ color: "#91989c" }} />
                    &nbsp;{t("Home")}
                  </Link>
                  <Link to="/facilities" className="single-footer-link">
                    <i className="fa fa-angle-right" style={{ color: "#91989c" }} />
                    &nbsp;{t("Facilities")}
                  </Link>
                  <Link to="/decks" className="single-footer-link">
                    <i className="fa fa-angle-right" style={{ color: "#91989c" }} />
                    &nbsp;{t("Decks")}
                  </Link>
                  <Link to="/itineraries" className="single-footer-link">
                    <i className="fa fa-angle-right" style={{ color: "#91989c" }} />
                    &nbsp;{t("Itineraries")}
                  </Link>
                  <Link to="/gallery" className="single-footer-link">
                    <i className="fa fa-angle-right" style={{ color: "#91989c" }} />
                    &nbsp;{t("Gallery")}
                  </Link>
                  <Link to="/sightseeings" className="single-footer-link">
                    <i className="fa fa-angle-right" style={{ color: "#91989c" }} />
                    &nbsp;{t("Sightseeing")}
                  </Link>
                </div>
              </div>
              <div className="social-footer">
                <a
                  href={infoData && _.find(infoData.infos, { key: "facebook" }).value}
                  target="_blank"
                  className="social-icon"
                  rel="noreferrer">
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  href={infoData && _.find(infoData.infos, { key: "twitter" }).value}
                  target="_blank"
                  className="social-icon"
                  rel="noreferrer">
                  <i className="fab fa-twitter" />
                </a>
                <a
                  href={infoData && _.find(infoData.infos, { key: "instagram" }).value}
                  target="_blank"
                  className="social-icon"
                  rel="noreferrer">
                  <i className="fab fa-instagram" />
                </a>
                <a
                  href={infoData && _.find(infoData.infos, { key: "tripadvisor" }).value}
                  target="_blank"
                  className="social-icon"
                  rel="noreferrer">
                  <i className="fab fa-tripadvisor" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 text-center">
              <div className="widget">
                <h4 className="footer-title">{t("CONTACT US")}</h4>
                <div className="text-para">
                  <p>
                    <i className="fa fa-map-marker-alt m-r-10" />
                    &nbsp;
                    {infoData && _.find(infoData.infos, { key: "address" }).value},
                  </p>
                  <p>
                    <i className="fa fa-envelope m-r-10" />
                    &nbsp;
                    {infoData && _.find(infoData.infos, { key: "email" }).value}
                  </p>
                  <p>
                    <i className="fa fa-phone m-r-10" />
                    <a
                      href={`tel:${infoData && _.find(infoData.infos, { key: "phone" }).value}`}
                      style={{ color: "#fff" }}>
                      &nbsp;
                      {infoData && _.find(infoData.infos, { key: "phone" }).value}
                    </a>
                    &nbsp;&nbsp;&nbsp;
                    <a
                      href={`tel:${
                        infoData && _.find(infoData.infos, { key: "emergencyContact1" }).value
                      }`}
                      style={{ color: "#fff" }}>
                      &nbsp;
                      {infoData && _.find(infoData.infos, { key: "emergencyContact1" }).value}
                    </a>
                  </p>
                </div>
              </div>
              {/* <h4 className="widget-title">{t("NEWSLETTER")}</h4> */}
              <div className="textwidget">
                <form onSubmit={handleSubmit(onSubmit)} className="wpcf7-form">
                  <div className="form-inline">
                    <div className="form-group" style={{ display: "flex" }}>
                      <input
                        type="text"
                        name="email"
                        size={40}
                        required
                        ref={register({ required: true })}
                        className="form-control"
                        aria-invalid="false"
                        placeholder={t("Email")}
                      />
                      <button type="submit" className="sbt-btn">
                        {t("Send")}
                      </button>
                      <span className="ajax-loader" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>{t("Copyright © 2020 Farah Nile Cruise. All right reserved")}</p>
          <img src="img/visa.png" alt="" className="visa" />
          <img src="img/masterCard.png" alt="" className="masterCard" />
          <img src="img/arab_african.png" alt="" className="arab-african" />
        </div>
      </footer>
    </>
  );
};

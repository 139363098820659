import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import windowDimensions from "../../services/windowDimensions";

export const DeckModal = ({ img, isOpen, setIsOpen }) => {
  const { width } = windowDimensions();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "solid #615533",
      overflow: "auto",
      height: width > 768 ? "500px" : "",
      width: width > 768 ? "770px" : "",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      zIndex: 999999999999,
    },
  };
  return (
    <ReactModal isOpen={isOpen} style={customStyles} onRequestClose={() => setIsOpen(false)}>
      <div className="deck-img">
        <img src={img} alt="" />
      </div>
    </ReactModal>
  );
};

DeckModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
};

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Transition } from "react-transition-group";
import {
  RecommendedTrips,
  Intro,
  Unique,
  Gallery,
  CheckAvailability,
  Partners,
  TripAdvisor,
  Facilities,
} from "./index";
import useWindowPosition from "../../services/windowPosition";
import { Header, Spinner } from "../../components";
import api from "../../services/api";
import { changeLang } from "../../redux/actions";

const duration = 800;
const heightScroll = 250;

export const Home = () => {
  const [{ data: homePageData, loading: homePageLoading }] = api.useAxios({
    url: "/homePage",
  });

  const [{ data: tripData, loading: tripLoading }] = api.useAxios({ url: "/trips" });

  const lang = useSelector((state) => state.langState.locale);

  const dispatch = useDispatch();

  const scrollPosition = useWindowPosition();
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    if (scrollPosition > heightScroll && !inProp) {
      setInProp(true);
    }
    if (scrollPosition < heightScroll && inProp) {
      setInProp(false);
    }
  }, [scrollPosition]);

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const { t } = useTranslation();

  const handleLang = (locale) => {
    if (lang !== locale) {
      const promise = new Promise((resolve) => {
        resolve(dispatch(changeLang(locale)));
      });
      promise.then(() => window.location.reload());
    }
  };

  // console.log(homePageData && Object.keys(homePageData.homeGallery));

  return (
    <>
      <Spinner isOpen={homePageLoading || tripLoading} />
      <div>
        {scrollPosition < heightScroll ? (
          <header>
            <div className="top-bar">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-xs-12" />
                  <div className="col-md-6 col-xs-12">
                    <div className="langs">
                      <ul>
                        <li>
                          <a style={{ cursor: "pointer" }}>
                            <img src="img/brtn.png" alt="" onClick={() => handleLang("en")} />
                          </a>
                        </li>
                        <li>
                          <a style={{ cursor: "pointer" }}>
                            <img src="img/prt.png" alt="" onClick={() => handleLang("pt")} />
                          </a>
                        </li>
                        <li>
                          <a style={{ cursor: "pointer" }}>
                            <img src="img/spn.png" alt="" onClick={() => handleLang("es")} />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="top-menu">
                      <ul>
                        <li>
                          <Link to="/terms">{t("Terms & Conditions")}</Link>
                        </li>
                        <li>
                          <Link to="/contact">{t("Contact Us")}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-bar">
              <div className="container">
                <div className="row">
                  <div className="mid-box clearfix">
                    <div className="col-md-1 col-sm-2 col-xs-12">
                      <div className="logo-box">
                        <a href="#">
                          <img src="img/logo-w.png" alt="Logistics" />
                        </a>
                      </div>
                    </div>
                    <div className="col-md-11 col-sm-10 col-xs-12">
                      <nav className="navbar navbar-default bootsnav clearfix">
                        <div className="navbar-header">
                          <button
                            type="button"
                            className="navbar-toggle"
                            data-toggle="collapse"
                            data-target="#navbar-menu">
                            <i className="fa fa-bars" />
                          </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbar-menu">
                          <ul
                            className="nav navbar-nav navbar-right nav-bar-intro"
                            data-in="fadeInDown"
                            data-out="fadeOutUp">
                            <li className="active">
                              <a href="#" className="header-btns">
                                {t("Home")}
                              </a>
                            </li>
                            <li>
                              <Link to="/facilities" className="header-btns">
                                {t("Facilities")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/decks" className="header-btns">
                                {t("Decks")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/itineraries" className="header-btns">
                                {t("Itineraries")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/gallery" className="header-btns">
                                {t("Gallery")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/sightseeings" className="header-btns">
                                {t("Sightseeing")}
                              </Link>
                            </li>
                            <li>
                              <div className="crsl-btn">
                                <Link
                                  to={{ pathname: "/booking", data: null }}
                                  className="btn btn-new btn-home bt2-hover">
                                  <span style={{ paddingLeft: "2rem" }}>{t("Book Now")}</span>
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        ) : (
          <Transition in={inProp} timeout={duration}>
            {(state) => (
              <Header
                show
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
                tabNo={1}
              />
            )}
          </Transition>
        )}

        {homePageData ? (
          <Intro sliderPhotos={homePageData.sliderPhotos} />
        ) : (
          <div className="fallback-intro" />
        )}
        <div className="check-res">
          <CheckAvailability />
        </div>
        <main>
          <section className="desti">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <RecommendedTrips tripData={tripData} />
                </div>
              </div>
            </div>
          </section>
          <section className="facilitis p-t-30 m-b-50">
            <div className="container">
              <div className="row">
                {homePageData && <Facilities facilityHome={homePageData.facilityHome} />}
              </div>
            </div>
          </section>

          <section className="ftrs p-t-50 m-b-50">
            <div className="container-fluid no-pad">
              <div className="row">
                {homePageData && (
                  <Unique
                    unique={homePageData.unique}
                    uniquePhoto={homePageData.uniquePhoto.photo}
                  />
                )}
              </div>
            </div>
          </section>
          <section className="gallery p-t-50 m-b-50">
            <div className="container-fluid no-pad">
              <div className="row">
                <div className="gallery-container">
                  <div className="section-name gallery-title">
                    <span className="desc-text">{t("Our")}</span>
                    <br />
                    {t("Gallery")}
                    <br />
                  </div>
                  <div className="gallery-grid">
                    {homePageData &&
                      Object.keys(homePageData.homeGallery).map((key) => (
                        <Gallery homeGallery={homePageData.homeGallery[key]} type={key} />
                      ))}
                  </div>
                  <div className="btn-gallery" style={{ cursor: "pointer" }}>
                    <Link to="/gallery" style={{ color: "black" }}>
                      {t("View More")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="gallery p-t-50 m-b-50">
            <div className="container">
              <div className="row">
                {homePageData && <TripAdvisor tripadvisor={homePageData.tripadvisor} />}
              </div>
            </div>
          </section>
          <section className="prtnrs p-t-50 m-b-50">
            <div className="container">
              <div className="row prtnrs-flex">
                {homePageData && <Partners partners={homePageData.partners} />}
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import convertImgName from "../../services/convertImgName";
import { getYoutubeUrl, youtubeParser } from "../../helperFunctions";

export const Facilities = ({ facilityHome }) => {
  const { t } = useTranslation();
  const isImage = facilityHome.photo.match(/\.(jpeg|jpg|gif|png)$/);
  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <div className="section-name" style={{ textAlign: "center" }}>
            <span className="desc-text">{t("Farah's")}</span>
            <br />
            {t("Facilities")}
            {/* {facilityHome?.linke.replace("watch?v=", "embed/")} */}
            <br />
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <iframe
            allowFullScreen
            src={`https://www.youtube.com/embed/${youtubeParser(facilityHome.linke)}`}
            title="facility-video"
            className="video-fac"
            controls
          />
          {/* {isImage ? (
            <img
              src={facilityHome.photo}
              className="img-responsive"
              alt={convertImgName(facilityHome.photo)}
            />
          ) : (
            <video className="video-fac" controls>
              <source
                src={facilityHome.photo}
                type="video/mp4"
                alt={convertImgName(facilityHome.photo)}
              />
            </video>
          )} */}
        </div>
        <div className="col-md-6 col-xs-12">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: facilityHome.content }} />
          <p className="text-right">
            <Link to="/facilities">{t("Read More")}</Link>
          </p>
        </div>
      </div>
    </>
  );
};

Facilities.propTypes = {
  facilityHome: PropTypes.shape({
    photo: PropTypes.string,
    linke: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { setAddtionalTrip, removeAddtionalTrip, setAddtionalPrice } from "../../redux/actions";
import { Modal, Spinner } from "../../components";
import api from "../../services/api";
import convertImgName from "../../services/convertImgName";
import repeatCarousel from "../../utils/repeatCarousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const Sightseeing = () => {
  const addtionalTrip = useSelector((state) => state.tripState.addtionalTrip);
  const tripDetails = useSelector((state) => state.tripState.trip);

  const [isOpen, setIsOpen] = useState(false);
  const [singleTrip, setSingleTrip] = useState(null);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [{ data: additionalTripsData, loading }] = api.useAxios({
    url: `/tripAdditionals/${tripDetails.id}`,
  });

  useEffect(() => {
    const addtionalPrice = _.sumBy(addtionalTrip, "totalPrice");

    dispatch(setAddtionalPrice(addtionalPrice));
  }, [addtionalTrip, dispatch]);

  const CustomDot = ({ onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <span className={active ? "dactive dots" : "dots"} />
      </li>
    );
  };

  const renderTrips = (trips) => {
    if (trips.length === 0) {
      return <div className="no-trips">{t("No Availability")}</div>;
    }
    return repeatCarousel(trips).map((trip) => {
      const addtionalTripItem = {
        id: trip.id,
        name: trip.name,
        location: trip.location,
        price: trip.price,
        img: trip.img,
        totalPrice: 0,
        totalGuest: 0,
        single_price: trip.SinglePrice,
      };

      const openModal = (tripData) => {
        setIsOpen(true);
        setSingleTrip(tripData);
      };

      return (
        <div className="item recomd-item" key={trip.id}>
          <a style={{ cursor: "pointer" }} onClick={() => openModal(trip)}>
            <img src={trip.img} alt={convertImgName(trip.img)} />
          </a>
          <div className="prodct">
            <h3 style={{ textAlign: "center", height: "40px" }}>{trip.name}</h3>
            <hr />
            <h3 style={{ textAlign: "center" }}>{trip.location}</h3>
            <p className="view-more" onClick={() => openModal(trip)}>
              {t("View More Details")}
            </p>

            <div className="crsl-btn">
              {!_.find(addtionalTrip, { id: trip.id }) && (
                <a
                  className="btn btn-new"
                  style={{ color: "white" }}
                  onClick={() => dispatch(setAddtionalTrip(addtionalTripItem))}>
                  {t("Add Trip")}
                </a>
              )}
              {_.find(addtionalTrip, { id: trip.id }) && (
                <a
                  className="btn btn-new"
                  style={{ color: "white", backgroundColor: "red" }}
                  onClick={() => dispatch(removeAddtionalTrip(trip))}>
                  {t("Remove Trip")}
                </a>
              )}
            </div>
          </div>
          <div className="trip-price">
            ${trip.price}
            <br />
            <span style={{ fontSize: "10px" }}>Per Person</span>
          </div>
        </div>
      );
    });
  };

  return (
    <div style={{ marginBottom: "4rem" }}>
      <Spinner isOpen={loading} />
      {singleTrip && <Modal isOpen={isOpen} trip={singleTrip} setIsOpen={setIsOpen} />}
      <div className="section-name" style={{ textAlign: "center", fontSize: "3rem" }}>
        <br />
        {t("Addtional Sightseeing")}:
        <br />
      </div>
      {additionalTripsData && (
        <Carousel
          responsive={responsive}
          showDots
          infinite
          autoPlay
          autoPlaySpeed={2000}
          arrows
          keyBoardControl
          customDot={<CustomDot />}>
          {renderTrips(additionalTripsData.tripAdditionals)}
        </Carousel>
      )}
    </div>
  );
};

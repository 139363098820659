import React from "react";
import { useTranslation } from "react-i18next";
import { Header, Spinner } from "../../components";
import api from "../../services/api";

export const Terms = () => {
  const [{ data, loading }] = api.useAxios("pages/2");

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Spinner isOpen={loading} />
      <div className="container">
        <div className="row m-b-50">
          <div className="col-md-2" />
          <div className="col-md-8  col-xs-12 wow animated fadeInLeft p-t-20">
            <div className="section-name m-t-150 m-b-20 m-res">
              <span className="desc-text">{t("Our Terms")}</span>
              <br />
              {t("Terms & Conditions")}
              <br />
            </div>
            {data && <div className="main-p" dangerouslySetInnerHTML={{ __html: data.page }} />}
          </div>
          <div className="col-md-2" />
        </div>
      </div>
      {loading && <div className="fallback" />}
    </>
  );
};

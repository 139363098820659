// lang
export const CHANGE_LANG = "CHANGE_LANG";

// trip
export const TRIP = "TRIP";
export const USER_DETAILS = "USER_DETAILS";
export const ACCOMMODATION = "ACCOMMODATION";
export const SET_PRICE = "SET_PRICE";
export const SET_CABIN_PRICE = "SET_CABIN_PRICE";
export const SET_SUITE_PRICE = "SET_SUITE_PRICE";
export const SET_CABINS_OPTION = "SET_CABINS_OPTION";
export const SET_SUITES_OPTION = "SET_SUITES_OPTION";
export const SET_GUEST = "SET_GUEST";
export const SET_ADDTIONAL_TRIP = "SET_ADDTIONAL_TRIP";
export const REMOVE_ADDTIONAL_TRIP = "REMOVE_ADDTIONAL_TRIP";
export const SET_ADDTIONAL_PRICE = "SET_ADDTIONAL_PRICE";
export const ADULT_COUNT = "ADULT_COUNT";
export const CHILD_COUNT = "CHILD_COUNT";
export const RESET_DATA = "RESET_DATA";
export const SET_COUPON = "SET_COUPON";
export const CLEAR_CODE = "CLEAR_CODE";

// checkout
export const SET_CHECKOUT = "SET_CHECKOUT";

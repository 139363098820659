/* eslint-disable react/no-danger */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Header, Spinner } from "../../components";
import api from "../../services/api";
import { DeckModal } from "./deckModal";
import { SingleDeck } from "./singleDeck";

export const Decks = () => {
  const [{ data: decks, loading: decksLoading }] = api.useAxios({
    url: `/decks`,
  });

  const [{ data: textData, loading: textLoading }] = api.useAxios("pages/6");

  const [isOpen, setIsOpen] = useState(false);
  const [deckImg, setDeckImg] = useState(null);

  const openModal = (img) => {
    setIsOpen(true);
    setDeckImg(img);
  };

  const renderDecks = () => {
    return decks.decks.map((deck, index) => (
      <SingleDeck deck={deck} index={index + 1} key={deck.id} openModal={openModal} />
    ));
  };

  const { t } = useTranslation();

  return (
    <>
      <Header tabNo={3} />
      <Spinner isOpen={decksLoading || textLoading} />
      {decks && <DeckModal isOpen={isOpen} img={deckImg} setIsOpen={setIsOpen} />}
      <div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-12 wow animated fadeInLeft p-t-50">
                <div className="section-name m-t-100 m-b-20 m-res" style={{ textAlign: "center" }}>
                  <span className="desc-text">{t("Farah's")}</span>
                  <br />
                  {t("Cruise Decks")}
                  <br />
                </div>
                {textData && (
                  <div className="main-p" dangerouslySetInnerHTML={{ __html: textData.page }} />
                )}
              </div>
            </div>
            <hr className="m-b-50" />
          </div>
        </div>
        {decks && renderDecks()}
      </div>
      {(decksLoading || textLoading) && <div className="fallback" />}
    </>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setTripDetails } from "../../redux/actions";

export const Trip = ({ setSubStep, data, setSingleTrip }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div className="results">
      <div className="result-box">
        <div className="rslt-img">
          <img
            src={data.trip_category.photo}
            alt=""
            style={{ height: "160px", "object-fit": "cover" }}
          />
        </div>
        <div className="rslt-info">
          <h3>{data.trip_category.name}</h3>
          <h4>
            <span>{data.trip_category.duration}</span>
          </h4>
          <h5>
            {t("Check in")}: <span>{data.check_in}</span>
          </h5>
          <h5>
            {t("Check out")}: <span>{data.check_out}</span>
          </h5>
          {/* <a href="#">Check itinerary</a> */}
        </div>
      </div>
      <div className="clearfix" />
      <table className="table table-responsive mytable">
        <thead>
          <tr style={{ color: "black" }}>
            <th scope="col" style={{ color: "black" }}>
              {t("Available")}
            </th>
            <th scope="col" style={{ color: "black" }}>
              {t("Rate Plan")}
            </th>
            <th scope="col" style={{ color: "black" }}>
              {t("Total")}
            </th>
            <th>{""}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              {data.cabin_available} {t("Cabin")}
              <br /> {data.suite_available} {t("Suite")}
            </th>
            <td>
              <strong>{t("Rates")}:</strong> {data.trip_category.rate_plan} <br />{" "}
              <strong>{t("Including")}:</strong> {t("Sightseeings")} - {t("Full Board")}
            </td>
            <td>
              <h4>
                <strong>${data.cabin_price}</strong>
              </h4>{" "}
              <small>{t("includes taxes")}</small>
              <br />
              <small>
                {t("start from")} ${data.cabin_price}
              </small>
            </td>
            <td>
              <button
                type="button"
                onClick={() => {
                  setSubStep(2);
                  dispatch(setTripDetails(data));
                  setSingleTrip(data);
                }}>
                {t("Select")}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

Trip.propTypes = {
  setSubStep: PropTypes.func.isRequired,
  setSingleTrip: PropTypes.func.isRequired,
  data: PropTypes.shape({
    trip_category: PropTypes.shape({
      name: PropTypes.string,
      duration: PropTypes.string,
      rate_plan: PropTypes.string,
      photo: PropTypes.string,
    }),
    check_in: PropTypes.string,
    check_out: PropTypes.string,
    cabin_available: PropTypes.number,
    suite_available: PropTypes.number,
    cabin_price: PropTypes.number,
  }).isRequired,
};

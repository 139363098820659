import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Swal from "sweetalert2";
import { SingleAccommodation } from "./singleAccommodation";
import {
  setAccommodation,
  setPrice,
  setCabinsOption,
  setSuiteOption,
  setGuest,
  setCabinPrice,
  setSuitePrice,
  setAdultCount,
  setChildCount,
  resetData,
} from "../../redux/actions";
import { ScrollToTop } from "../../components";

export const Accommodation = ({
  numofSuites,
  numofCabins,
  setSubStep,
  cabinPrice,
  suitePrice,
  cabinPriceSingle,
  suitePriceSingle,
}) => {
  const cabinsOption = useSelector((state) => state.tripState.cabinsOption);
  const suiteOption = useSelector((state) => state.tripState.suiteOption);
  const [accommodations, setAccommodations] = useState([]);
  const guest = useSelector((state) => state.tripState.guest);
  const [clicked, setClicked] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const cPrice = cabinPrice;
    const sPrice = suitePrice;
    const cPriceSingle = cabinPriceSingle;
    const sPriceSingle = suitePriceSingle;
    let allPrice = 0;
    let adultCount = 0;
    let childCount = 0;
    let allGuest = 0;
    let allcPrice = 0;
    let allsPrice = 0;
    for (let i = 0; i < accommodations.length; i += 1) {
      const child1 = accommodations[i].child1_age;
      const child2 = accommodations[i].child2_age;

      const calculatePrice = () => {
        const priceDouble = accommodations[i].type === 1 ? cPrice : sPrice;
        const priceSingle = accommodations[i].type === 1 ? cPriceSingle : sPriceSingle;

        let totalPrice = accommodations[i].adults_count > 1 ? priceDouble : priceSingle;
        totalPrice += Number(child1) === 2 ? priceDouble * 0.25 : 0;
        totalPrice += Number(child2) === 2 ? priceDouble * 0.25 : 0;

        return totalPrice;
      };

      if (accommodations[i].type === 1) {
        allcPrice += calculatePrice();
      } else {
        allsPrice += calculatePrice();
      }

      allPrice += calculatePrice();
      adultCount += Number(accommodations[i].adults_count);
      childCount += Number(accommodations[i].children_count);
      allGuest += Number(accommodations[i].adults_count) + Number(accommodations[i].children_count);
    }
    dispatch(setPrice(allPrice));
    dispatch(setGuest(allGuest));
    dispatch(setAdultCount(adultCount));
    dispatch(setChildCount(childCount));
    dispatch(setCabinPrice(allcPrice));
    dispatch(setSuitePrice(allsPrice));
  }, [accommodations]); // eslint-disable-line

  const makeAccommodationsArray = (type, count) => {
    const CArr = _.filter(accommodations, { type: 1 });
    const SArr = _.filter(accommodations, { type: 2 });
    const arr = [];
    for (let i = 1; i <= count; i += 1) {
      const exist = _.find(accommodations, { id: type + i });
      if (exist) {
        arr.push(exist);
      } else {
        const accommodation = {
          id: type + i,
          type: type === "Cabin" ? 1 : 2,
          adults_count: "0",
          children_count: "0",
          child1_age: null,
          child2_age: null,
          accommodation_num: null,
          guest_name: null,
          img: null,
        };
        arr.push(accommodation);
      }
    }
    const otherType = type === "Cabin" ? SArr : CArr;
    setAccommodations([...arr, ...otherType]);
  };

  const handleSelect = ({ target }) => {
    makeAccommodationsArray(target.name, target.value);
    if (target.name === "Cabin") {
      dispatch(setCabinsOption(target.value));
    }
    if (target.name === "Suite") {
      dispatch(setSuiteOption(target.value));
    }
  };

  const handleAccommodations = (accommodation) => {
    let newArr = [];
    const key = _.findKey(accommodations, { id: accommodation.id });
    if (!key) {
      newArr = [...accommodations, accommodation];
    } else {
      accommodations[key] = accommodation;
      newArr = [...accommodations];
    }
    setAccommodations(newArr);
  };

  const renderAccommodation = (type) => {
    const arr = [];
    const count = type === "Cabin" ? cabinsOption : suiteOption;
    for (let i = 1; i <= count; i += 1) {
      arr.push(
        <SingleAccommodation
          key={i}
          num={i}
          type={type}
          handleAccommodations={handleAccommodations}
          clicked={clicked}
        />,
      );
    }
    return arr;
  };

  const renderOptions = (type) => {
    const arr = [];
    const count = type === "Cabin" ? numofCabins : numofSuites;
    const typeName = type === "Cabin" ? t("Cabin") : t("Suite");
    for (let i = 1; i <= count; i += 1) {
      arr.push(
        <option value={i} key={i}>{`${i > 9 ? "" : "0"}${i} ${typeName}${
          i > 1 ? "s" : ""
        }`}</option>,
      );
    }
    return arr;
  };

  const validate = () => {
    if (accommodations.length === 0) {
      return null;
    }
    const adult = !!_.find(accommodations, { adults_count: "0" });
    const child1 = !!_.find(accommodations, { children_count: "1", child1_age: null });
    const child2 = !!_.find(
      accommodations,
      (acc) => acc.children_count === "2" && (!acc.child1_age || !acc.child2_age),
    );
    return adult || child1 || child2;
  };

  const handleClick = () => {
    setClicked(true);
    if (validate()) {
      Swal.fire({
        title: t("Error!"),
        text: t("All selected field must be filled"),
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else if (!guest) {
      Swal.fire({
        title: t("Error!"),
        text: t("You have to select at least one cabin or suite"),
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      setSubStep(3);
      dispatch(setAccommodation(accommodations));
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <div className="cbns-suts m-t-40">
        <div className="col-md-2 col-xs-12 lft" />
        <div className="col-md-10 col-xs-12 lft">
          <select id="colorselector" name="Cabin" className="cbns" onChange={handleSelect}>
            <option value={0}>{t("No. Of Cabins")}</option>
            {renderOptions("Cabin")}
          </select>
        </div>
        <div className="clearfix" />
        {renderAccommodation("Cabin")}
      </div>
      <div className="clearfix m-t-40 " />
      <hr />
      <div className="cbns-suts m-t-40">
        <div className="col-md-2 col-xs-12 lft" />
        <div className="col-md-10 col-xs-12 lft">
          <select id="colorselector1" name="Suite" className="cbns" onChange={handleSelect}>
            <option value={0}>{t("No. Of Suites")}</option>
            {renderOptions("Suite")}
          </select>
        </div>
        <div className="clearfix" />
        {renderAccommodation("Suite")}
      </div>
      <div className="clearfix m-t-40 " />
      <hr />
      <div className="hints m-t-40 m-b-30">
        <p>* {t("No Triple Cabins Available on Board")} </p>
        <p>* {t("The cabin can accomdate 2 adults plus 1 child or 1 adult plus 2 children")}</p>
        <p>
          <strong>* {t("Child Rate")}:</strong>{" "}
        </p>
        <p>
          1:5 {t("years")} ({t("Free")})
        </p>
        <p>6:11 {t("years")} (50%)</p>
      </div>
      <div className="clearfix" />
      <hr />
      <ul className="list-inline pull-right">
        <li>
          <button
            type="button"
            className="btn btn-primary btn-md-1 next-step"
            onClick={() => handleClick()}>
            {t("Save and continue")}
          </button>
        </li>
      </ul>
      <ul className="list-inline pull-left m-t-50">
        <li>
          <button
            type="button"
            className="btn btn-primary btn-md-1 prev-step"
            onClick={() => {
              setSubStep(1);
              dispatch(resetData());
            }}>
            {t("Previous")}
          </button>
        </li>
      </ul>
    </>
  );
};

Accommodation.propTypes = {
  numofSuites: PropTypes.number.isRequired,
  numofCabins: PropTypes.number.isRequired,
  setSubStep: PropTypes.func.isRequired,
  cabinPrice: PropTypes.number.isRequired,
  suitePrice: PropTypes.number.isRequired,
  cabinPriceSingle: PropTypes.number.isRequired,
  suitePriceSingle: PropTypes.number.isRequired,
};
